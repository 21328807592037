// src/pages/EssVideo.js
import React from "react";
import EssVideoElement from '../assets/EssVideo.mp4';

const EssVideo = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#000' }}>
      <video controls style={{ width: '100%', height: '100%' }}>
        <source src={EssVideoElement} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default EssVideo;
