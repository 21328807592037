import React from "react";
import VacancyCard from "./VacancyCard";
import { useLanguage } from "../config/LanguageContext";
import GSDVacancy from "./GSDVacancy";
import TestVacancy from "./TestVacancy";

function Vacancies() {
  const {currentLanguage, translations} = useLanguage();

  return (
    <div className="vacancies-container">
      <div className="vacancies-wrapper">
        <h1 className="secondary">
          {translations.vacancies[currentLanguage].header}
        </h1>
        <div className="vacancies-card-section-container">
          <div className="vacancies-card-section-wrapper">
            <GSDVacancy />
            <TestVacancy />
          </div>
          <p className="no-openings-container">No vacancies available at the moment.</p>
        </div>
      </div>
    </div>
  );
}

export default Vacancies;

{/* <VacancyCard openingKey="opening3" /> TEMPLATE DONT DELETE */}
