import React, { useState, useEffect } from "react";
import { useLanguage } from "../config/LanguageContext";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import TrustedBy from "../components/TrustedBy";
import LandingStats from "../components/LandingStats";
import FooterCertificates from "../components/FooterCertificates";
import Front from "../assets/heros/Front 1.svg";
import MeetUs from "../components/MeetUs";
import IndustryDiscover from "../components/IndustryDiscover";
import TypeWriter from "../components/Typewriter";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import HeaderSection from "../components/headerSections";

function Landing() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate("/contact");
  };

  const typeWriter = [
    "Government",
    "Education",
    "Fire Services",
    "Public Safety",
  ];

  return (
    <>
      <Nav />
      {/* <MeetUs /> */}
      <HeaderSection
        header='"The best way to predict the future is to invent it"'
        subHeader="Alan Curtis Kay (1971) is an American computer scientist, little known to the public, but whose pioneering work on Object Oriented Programming and windowing graphical user interfaces revolutionised the software industry and the way we interact with applications today."
        buttonText="Contact Us"
        isLandingButton={true}
        image={Front}
        onClick={handleContactUsClick}
      />
      <div className="right-to-left-triangle-white"></div>
      <div className="landing-information-container">
        <div className="landing-information-wrapper">
          <div>
            <h2>Rooted in Public Safety</h2>
            <p>
              “Innaxys” combines <b>innovation</b> and <b>systems</b>. Our
              journey started twelve years ago with the aim to develop
              science-driven software solutions to help public safety agencies
              deliver a better service to the community, more efficiently and
              with the best intelligence in hand.
              <br></br>
              <br></br>
              Today, whilst most of our applications started their life in the
              policing industry, they have evolved to serve other government and
              commercial organisations, still delivering efficiencies using an
              evidence-based and innovative approach.
            </p>
          </div>
          <div>
            <h2>Putting users in the driving seat</h2>
            <p>
              At a time when large tech suppliers frequently expect all their
              clients to use the same solution and adapt uniformly to their
              products, we take a different approach as a human-size software
              house. The Innaxys ethos is to work closely in partnership with
              clients to customise and develop a personalised software
              experience. You set the roadmap, and we develop it.{" "}
            </p>
          </div>
          <div>
            <h2>Investing in Britain</h2>
            <p>
              Whilst our CEO is French, we are a British company committed in
              our investment to the United Kingdom and its communities. All our
              software is developed by our close-knit team in the heart of
              Derbyshire, and we are a proud member of the Made in Britain
              initiative in support of our country’s economy.{" "}
            </p>
          </div>
          {/* <h2>
            {translations.landing[currentLanguage].softwareSolutions}{" "}
            <span className="landing-information-header green">
              <br className="typewriter-breakline"></br>
              <TypeWriter data={typeWriter} />
              <span className="blinker"></span>
            </span>
          </h2>
          <p>{translations.landing[currentLanguage].solutionsContext}</p>
          <h2>{translations.landing[currentLanguage].drivingSeat}</h2>
          <p>{translations.landing[currentLanguage].drivingSeatText1}</p>
          <p>{translations.landing[currentLanguage].drivingSeatText2}</p>
          <h2>{translations.landing[currentLanguage].evidence}</h2>
          <p>{translations.landing[currentLanguage].evidenceContext}</p> */}
        </div>
      </div>
      <IndustryDiscover />
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Landing;
