import React, { useEffect } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FooterCertificates from "../components/FooterCertificates";
import { useLanguage } from "../config/LanguageContext";
import { motion, useScroll } from "framer-motion";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const { currentLanguage, translations } = useLanguage();
  const { scrollYProgress } = useScroll();
  const navigate = useNavigate();

  const navigateToResources = () => {
    navigate("/resources");
  };

  // Function to scroll to a specific section by ID
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -200; // Adjust this value as needed
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionPosition + offset,
        behavior: "smooth"
      });
    }
  };

  const handleBackToOverviewKeyPress = (event) => {
    if (event.key === "Enter") {
      navigateToResources();
    }
  };

  const handleContentItemClick = (sectionId) => {
    scrollToSection(sectionId);
  };

  const handleContentItemKeyPress = (event, sectionId) => {
    if (event.key === "Enter") {
      scrollToSection(sectionId);
    }
  };

  useEffect(() => {
    const header = document.getElementById("myHeader");
    if (!header) return;

    const sticky = header.offsetTop;

    const handleScroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Nav />
      <div className="progress-bar-container">
        <motion.div
          className="progress-bar"
          style={{
            scaleX: scrollYProgress,
            backgroundColor: "var(--primary)",
            height: "10px",
          }}
        />
      </div>
      <div className="resources-container">
        <div className="resources-header-container">
          <div className="resources-wrapper">
            <h1 className="gray">
              {translations.policy[currentLanguage].title}
            </h1>
          </div>
        </div>
        <div className="header" id="myHeader">
          <div
            className="back-container"
            onClick={navigateToResources}
            onKeyDown={handleBackToOverviewKeyPress} // Added keydown event handler
            tabIndex="0" // Added tabindex to make it focusable
          >
            <div className="back-wrapper">
              <span className="back-to-overview">
                <IconArrowLeft size={20} />{" "}
                {translations.carbonReduction[currentLanguage].return}
              </span>
            </div>
          </div>
          <div className="progress-bar-container">
            <motion.div
              className="progress-bar"
              style={{
                scaleX: scrollYProgress,
                backgroundColor: "var(--primary)",
                height: "10px",
              }}
            />
          </div>
        </div>
        <div className="resources-content-container">
          <div className="resources-contents-wrapper">
            <div className="resources-contents-container">
              <h2>{translations.policy[currentLanguage].contents.header}</h2>
              <ul className="resources-contents-list">
                {translations.policy[currentLanguage].contents.list.map(
                  (items, index) => (
                    <li
                      key={index}
                      onClick={() => handleContentItemClick(items.id)}
                      onKeyDown={(event) =>
                        handleContentItemKeyPress(event, items.id)
                      } // Added keydown event handler
                      tabIndex="0" // Added tabindex to make it focusable
                    >
                      {items.label}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="resources-body-container">
              {translations.policy[currentLanguage].textHead.map(
                (textHead, index) => (
                  <p key={index}>{textHead}</p>
                )
              )}
              <h4 className="secondary">
                {translations.policy[currentLanguage].provisions.header}
              </h4>
              {translations.policy[currentLanguage].provisions.body.map(
                (textHead, index) => (
                  <p key={index}>{textHead}</p>
                )
              )}
              <h2 id="terms" className="secondary">
                {translations.policy[currentLanguage].terms.header}
              </h2>
              <p>{translations.policy[currentLanguage].terms.body}</p>
              <h2 id="statement">{translations.policy[currentLanguage].statement.header}</h2>
              {translations.policy[currentLanguage].statement.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h4 className="secondary">
                {translations.policy[currentLanguage].list.header}
              </h4>
              <ul className="resources-list">
                {translations.policy[currentLanguage].list.items.map(
                  (items, index) => (
                    <li key={index}>{items}</li>
                  )
                )}
              </ul>
              <h2 id="info">{translations.policy[currentLanguage].info.header}</h2>
              {translations.policy[currentLanguage].info.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h2 id="user">{translations.policy[currentLanguage].user.header}</h2>
              {translations.policy[currentLanguage].user.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h2 id="warranties">{translations.policy[currentLanguage].warranties.header}</h2>
              {translations.policy[currentLanguage].warranties.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h2 id="endorsements">
                {translations.policy[currentLanguage].endorsements.header}
              </h2>
              {translations.policy[currentLanguage].endorsements.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h2 id="trade">{translations.policy[currentLanguage].trade.header}</h2>
              <p>{translations.policy[currentLanguage].trade.body}</p>
              <h2 id="internet">{translations.policy[currentLanguage].internet.header}</h2>
              {translations.policy[currentLanguage].internet.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h2 id="social">{translations.policy[currentLanguage].social.header}</h2>
              {translations.policy[currentLanguage].social.body.map(
                (body, index) => (
                  <p key={index}>{body}</p>
                )
              )}
              <h2 id="law">{translations.policy[currentLanguage].law.header}</h2>
              <p>{translations.policy[currentLanguage].law.body}</p>
              <h2 id="queries">{translations.policy[currentLanguage].queries.header}</h2>
              <p>{translations.policy[currentLanguage].queries.body}</p>
            </div>
          </div>
        </div>
      </div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
