export const discoverTranslations = {
  en: {
    header: "How can Innaxys help your business?",
    subheading: "Our sales team can walk you through anything you need to know",
    ctaButton: "Schedule a demo"
  },
  fr: {
    header: "Notre équipe de vente peut vous aider à comprendre tout ce que vous avez besoin de savoir.",
    subheading: "Notre équipe de vente peut vous aider à comprendre tout ce que vous avez besoin de savoir.",
    ctaButton: "Planifier une démo"
  }
}