export const dytaskTranslations = {
  en: {
    dytaskHeader: "DyTASK - GIS for Policing, with demand prediction and effective patrol and tasking management",
    dytaskHeaderButton: "Request a demo",
    dytaskContent: {
      header:
        "Using intelligence and crime science, DyTASK redefines evidence-based policing with smart tasking and briefing.",
      textHead: [
        "With our powerful GIS at its core, the DyTASK app dynamically tasks officers and monitors their delivery and effectiveness, to improve ground team efficiency and accountability and help desk-based analysts understand the impact of new strategies through detailed reporting.",
        "It facilitates the real time tasking and briefing of officers using location-based services and metrics-based targets to trigger the right information to be delivered to the right people at the right time and the right place on a mobile device.",
      ],
      imagine: "Imagine",
      text: [
        "Making significant operational and cashable savings for your force whilst improving policing visibility in the fight against crime...",
        // "Knowing in advance where the expected ‘hotspots’ of criminal activity are emerging, and having your officers in the right locations as, or even before, anything happens…",
        // "Being able to brief officers and patrols in real time whilst they are out in the community, saving time and money on repeat journeys back to the station…",
        // "Being able to monitor officers’ locations both live and retrospectively against a variety of objectives and being able to optimise dispatch according to demand…"
      ]
    },
  },
  fr: {
    dytaskHeader: "DyTASK - Optimisez vos effectifs et améliorez la sécurité publique",
    dytaskHeaderButton: "Réserver une démonstration",
    dytaskContent: {
      header:
        "S'appuyant sur l'intelligence et la science criminelle, DyTASK redéfinit la police basée sur les faits grâce à l'attribution intelligente de tâches et de briefings.",
      textHead: [
        "Grâce à notre puissant SIG, l'application DyTASK attribue dynamiquement des tâches aux agents et surveille leur exécution et leur efficacité, afin d'améliorer l'efficacité et la responsabilité des équipes sur le terrain et d'aider les analystes à comprendre l'impact des nouvelles stratégies grâce à des rapports détaillés.",
        "Il facilite l'attribution des tâches et le briefing des agents en temps réel en utilisant des services basés sur la localisation et des objectifs basés sur des mesures pour déclencher la transmission des bonnes informations aux bonnes personnes au bon moment et au bon endroit sur un appareil mobile.",
      ],
      imagine: "Imaginez",
      text: [
        "Réaliser d'importantes économies opérationnelles et financières pour votre service tout en améliorant la visibilité de la police dans la lutte contre la criminalité...",
        "Connaître à l'avance les 'points chauds' de l'activité criminelle et placer ses agents aux bons endroits lorsque, ou même avant, quelque chose se produit...",
        "La possibilité d'informer les agents et les patrouilles en temps réel pendant qu'ils sont sur le terrain, ce qui permet d'économiser du temps et de l'argent sur les allers-retours au poste de police...",
        "La possibilité de contrôler en direct et a posteriori la localisation des agents en fonction de divers objectifs et d'optimiser la répartition en fonction de la demande..."
      ]
    },
  },
};
