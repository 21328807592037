import React, { useState } from "react";

const CareersForm = () => {
  const [status, setStatus] = useState("Apply");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    const formData = new FormData();
    formData.append("firstName", e.target.elements.firstName.value);
    formData.append("lastName", e.target.elements.lastName.value);
    formData.append("email", e.target.elements.email.value);
    formData.append("phone", e.target.elements.phone.value);
    formData.append("portfolio", e.target.elements.portfolio.value);
    formData.append("message", e.target.elements.message.value);
    formData.append("resume", e.target.elements.resume.files[0]); 

    try {
      const response = await fetch("http://localhost:5000/careers", {
        method: "POST",
        body: formData,
      });
      
      setStatus("Apply");
      const result = await response.json();
      alert(result.status);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form action="./mailer.php" method="POST">
      <div className="careers-form-header-container">
        <span>We'd love to hear from you</span>
      </div>
      <div className="form-input-container">
        <div className="singular-input-container">
          <label htmlFor="firstName">First Name</label>
          <input type="text" id="firstName" name="firstName" required />
        </div>
        <div className="singular-input-container">
          <label htmlFor="lastName">Last Name</label>
          <input type="text" id="lastName" name="lastName" required />
        </div>
      </div>
      <div className="form-input-container">
        <div className="singular-input-container">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="singular-input-container">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            pattern="[0-9]{10}"
            required
          />
        </div>
      </div>
      {/* <div className="form-input-container-full">
        <label htmlFor="portfolio">
          Portfolio
        </label>
        <input type="url" id="portfolio" name="portfolio" />
      </div>
      <div className="form-resume-container">
        <label htmlFor="resume">
          Resume 
        </label>
        <input
          className="resume-input"
          type="file"
          id="resume"
          name="resume"
          accept=".pdf"
          required // Add required attribute for validation
        />
        <p className="resume-guideline">Upload PDF only (max 5MB)</p>
      </div> */}
      <div className="form-input-container-full">
        <label htmlFor="message">
          Supporting Text
        </label>
        <textarea id="message" name="message" required></textarea>
      </div>
      <div className="submit-button-container">
        <button className="cta-button-large-careers" type="submit">
          {status}
        </button>
      </div>
      <input type="hidden" name="subject" />
    </form>
  );
};

export default CareersForm;
