import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import InnaxysOnMap from "../assets/MapPicture.jpg";
import BookingForm from "../components/BookingForm";
import FooterCertificates from "../components/FooterCertificates";
import ContactForm from "../config/ContactForm";

function Contact() {
  return (
    <>
      <Nav />
      <div className="contact-image-container">
        <img
          src={InnaxysOnMap}
          alt="Innaxys Location"
          className="innaxys-on-map"
        />
      </div>
      <div className="right-to-left-triangle-white-contact"></div>
      <div className="chips">
        <div className="contact-form-header">
          <h1 className="secondary">Contact us</h1>
        </div>
      </div>
      <div className="contact-form-container">
        <div className="contact-form-wrapper">
          <ContactForm />
        </div>
      </div>

      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Contact;
