import React, { useState } from "react";
import {
  IconFolder,
  IconCalendarEvent,
  IconCalendar,
  IconCaretDownFilled,
} from "@tabler/icons-react";
import { useLanguage } from "../config/LanguageContext";

function GSDVacancyCard({ openingKey }) {
  const { currentLanguage, translations } = useLanguage();
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const vacancyData = translations.vacancies[currentLanguage][openingKey];

  return (
    <>
      <div
        className="vacancy-card-container"
        tabIndex={0}
        onClick={toggleActive}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            toggleActive();
          }
        }}
      >
        <div className="vacancy-card-wrapper">
          <div className="vacancy-details-wrapper">
            <h2 className="vacancy-card-title">
              Test and Support Engineer with a developer’s background
            </h2>
            {/* <div className="vacancy-card-info-wrapper">
              <span className="vacancy-card-date" title="Closing Date">
                <IconCalendar />
                01/02/2024
              </span>
            </div> */}
          </div>
          <div className="caret-wrapper">
            <IconCaretDownFilled
              size={30}
              className={`caret ${isActive ? "active" : ""}`}
            />
          </div>
        </div>
        <div
          className={`vacancy-description ${isActive ? "visible" : "hidden"}`}
        >
          <div>
            <p>
              Whilst a significant part of testing is handled by the development
              team, we also perform a full system and user acceptance testing
              prior to each release. This involves some manual testing but also
              writing code to automate the testing process. So the role is not
              just testing, there is some development, but not as much
              Product-oriented.
              <br></br>
              <br></br>
              The role also includes handling support calls from clients,
              logging them and following up – so a communication role.
            </p>
            <ul>
              <span>What you will do</span>
              <li>
                Your role will be two-fold, (1) Tester and (2) Support and
                Maintenance.
              </li>
              <li>
                In your testing capacity, you will collaborate with the dev team
                to set quality targets, test and automate testing. You will also
                perform code reviews to ensure quality standards remain fully
                adhered to and you will contribute to setting those standards.
              </li>
              <li>
                You will be responsible for releasing solutions to our clients.
              </li>
              <li>
                In your support role, you will handle client support requests
                coming through our ITIL portal, or through emails and phone
                calls. You will follow them up and work with the development
                team to resolve issues.
              </li>
            </ul>
            <ul>
              <span>What we need from you</span>
              <li>Some coding experience in Java, HTML5 and Javascript.</li>
              <li>
                Good communication skills to interact in a friendly and
                professional manner with our clients, and manage our support
                portal.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default GSDVacancyCard;
