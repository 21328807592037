import React from "react";
import nics from "../assets/trusted/nics.svg";
import cefas from "../assets/trusted/cefas.svg";
import apha from "../assets/trusted/apha.svg";
import met from "../assets/trusted/met.svg";
import cheshire from "../assets/trusted/cheshire.svg";

function TrustedBy() {
  const trustedCompanyLogos = [
    {
      link: nics,
    },
    {
      link: cefas,
    },
    {
      link: apha,
    },
    // {
    //   link: met,
    // },
    {
      link: cheshire,
    },
  ];

  return (
    <>
    <span className="wraps">
      <div className="right-to-left-triangle"></div>
    </span>
      <div className="trusted-by-container">
        <div className="trusted-by-section">
          <div className="trusted-logo-container">
            {trustedCompanyLogos.map((item, index) => (
              <img
                className="trusted-logo"
                key={index}
                src={item.link}
                alt={`Trusted Company Logo ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="upside-down-right-to-left-triangle"></div>
    </>
  );
}

export default TrustedBy;
