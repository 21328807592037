import React, { createContext, useContext, useState } from "react";
import { landingTranslations } from "../translation/landingTranslations"; 
import { trustedByTranslations } from "../translation/trustedByTranslations";
import { navTranslations } from "../translation/navTranslations";
import { industryTranslations } from "../translation/industryTranslations";
import { discoverTranslations } from "../translation/discoverTranslations";
import { productCardTranslations } from "../translation/productCardTranslations";
import { carbonReductionTranslations } from "../translation/carbonReductionTranslations";
import { aboutUsTranslations } from "../translation/aboutUsTranslations";
import { careersTranslations } from "../translation/careersTranslations";
import { vacanciesTranslations } from "../translation/vacanciesTranslations";
import { dytaskTranslations } from "../translation/dytaskTranslations";
import { tramTranslations } from "../translation/tramTranslations";
import { productFeaturesTranslations } from "../translation/productFeaturesTranslations";
import { damsTranslations } from "../translation/damsTranslations";
import { privacyPolicyTranslations } from "../translation/privacyPolicyTranslations";
import { resourcesTranslations } from "../translation/resourcesTranslations";
import { cookiesTranslations } from "../translation/cookiesTranslations";


const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState("en");

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
  };

  const translations = {
    landing: landingTranslations,
    productCard: productCardTranslations,
    trustedBy: trustedByTranslations,
    nav: navTranslations,
    industry: industryTranslations,
    discover: discoverTranslations,
    carbonReduction: carbonReductionTranslations,
    aboutUs: aboutUsTranslations,
    careers: careersTranslations,
    vacancies: vacanciesTranslations,
    dytask: dytaskTranslations,
    tram: tramTranslations,
    productFeatures: productFeaturesTranslations,
    dams: damsTranslations,
    policy: privacyPolicyTranslations,
    resources: resourcesTranslations,
    cookies: cookiesTranslations
  };

  return (
    <LanguageContext.Provider
      value={{ currentLanguage, handleLanguageChange, translations }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
