import React from 'react'
import Nav from "../components/Nav";
import Officer from "../assets/heros/Officer.jpg";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import { useLanguage } from "../config/LanguageContext";
import TramFeatures from "../components/tramFeatures";
import { useNavigate } from "react-router-dom";

function Gis() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  return (
    <>
      <Nav />
      <div className="landing-container">
        <div className="landing-section">
          <div className="landing-wrapper">
            <div data-aos="fade-right" className="landing-content">
              <h1 className="landing-header">
                  GIS - Cloud-based Analytical Geographic Information System
              </h1>
              <button className="cta-button-large-white" onClick={handleBookDemoClick}>
                {translations.tram[currentLanguage].tramHeaderButton}
              </button>
            </div>
          </div>
        </div>
        <div className="landing-image">
          <img src={Officer} />
        </div>
      </div>
      <span className="wraps">
        <div className="right-to-left-triangle-white"></div>
      </span>
      <div className="dytask-content-container">
        <div className="dytask-content-wrapper">
          <p>{translations.tram[currentLanguage].tramContent.textHead}</p>
        </div>
      </div>
      <div className="left-to-right-triangle-products"></div>
      <div className="dytask-features-container">
        <TramFeatures />
      </div>
      <div className="upside-down-left-to-right-triangle"></div>
      <FooterCertificates />
      <Footer />
    </>
  )
}

export default Gis