export const privacyPolicyTranslations = {
  en: {
    title: "Privacy Statement & Terms",
    contents: {
      header: "Content",
      list: [
        { id: "policy", label: "Policy" },
        { id: "terms", label: "Site Terms" },
        { id: "statement", label: "Privacy Statement" },
        { id: "info", label: "Information" },
        { id: "user", label: "User of the Site" },
        { id: "warranties", label: "No Warranties" },
        { id: "endorsements", label: "Third Party Sites and Endorsements" },
        { id: "trade", label: "Trade Marks and Copyright" },
        { id: "internet", label: "Internet Email" },
        { id: "social", label: "Social Media" },
        { id: "law", label: "Governing Law" },
        { id: "queries", label: "Your Queries" },
      ],
    },
    textHead: [
      "This document sets out the Terms and Privacy Statement ('Terms') of innaxys.com and any sub-domain (the 'Site'), which is operated by Innaxys Ltd. By accessing this Site, you agree to be bound by the Terms. Before using this Site, you should read the Terms carefully, as well as our Cookie Policy ('Cookie Policy').",
      "Innaxys Ltd is a company incorporated under the laws of England and Wales with company registration number 7725890. For information about the Company, you can visit the Companies House website.",
    ],
    provisions: {
      header: "In these provisions:",
      body: [
        "'Content' means all pages, screens, information and materials included in or accessible through this Site (including any content available on any email or SMS services)",
        "'you', 'your' and 'yours' means you, the person(s) accessing this Site and the party on whose behalf you are doing so",
        "'we', 'us' and 'our' means Innaxys Ltd",
        "'Innaxys' means Innaxys Ltd, its subsidiaries and associated companies from time to time",
      ],
    },
    terms: {
      header: "Site Terms",
      body: "These Terms cover your use of this Site. All products and services provided by Innaxys have their own terms and conditions (the 'Product Terms'). You must read both the Product Terms and these Terms. To the extent that the Product Terms conflict with these Terms then the Product Terms will prevail.",
    },
    statement: {
      header: "Privacy Statement",
      body: [
        "This privacy statement sets out our current policies and demonstrates our commitment to your privacy. We may change the content or services found on our Site at any time without notice, and consequently our privacy statement and the Terms may change at any time in the future. You should also read our Cookie Policy to find out more about how Innaxys and our trusted partners use cookies, which is relevant to your online security: we use cookies to make our website more secure and easier to use.",
        "Innaxys Ltd provides software solutions and services for Public Safety through its affiliated companies and branches located worldwide. Privacy and personal data protection principles vary from one country to another. When you access or link to any other website, please read the privacy and data protection statements or agreements issued by such other website to determine the policies that apply to information or data maintained by that website.",
        "Innaxys's business has been built on trust between us and our customers.",
      ],
    },
    list: {
      header:
        "To preserve the confidentiality of all information you provide to us:",
      items: [
        "Innaxys will only collect information that it believes to be relevant and required to understand the customer's business needs and to conduct Innaxys's business.",
        "Innaxys will use customer information to provide customers with better customer services and products.",
        "Innaxys may pass customer information to other Innaxys companies or agents, as permitted by law.",
        "Innaxys will not disclose customer information to any external organisation unless Innaxys have customer consent or are required by law or have previously informed the customer.",
        "Innaxys may be required, from time to time, to disclose customer information to Governmental or judicial bodies or agencies but Innaxys will only do so under proper authority.",
        "Innaxys aim's to keep customer information up-to-date.",
        "Innaxys will maintain strict security systems designed to prevent unauthorised access to customer information by anyone, including Innaxys staff.",
        "All Innaxys companies, or staff and all third parties with permitted access to customer information are specifically required to observe Innaxys' confidentiality obligations.",
        "By maintaining our commitment to these principles, we will ensure that we respect the inherent trust that you place in Innaxys.",
      ],
    },
    info: {
      header: "Information",
      body: [
        "Information or opinions contained on this Site are purely informative and not aimed at guiding any decision process.",
        "Should you seek to rely in any way whatsoever upon any content contained on this Site, you do so at your own risk.",
      ],
    },
    user: {
      header: "User of the Site",
      body: [
        "The Site is currently intended for those who access it from within the United Kingdom. Because of this we cannot guarantee that the Site or the information thereon complies with law or regulation, or is appropriate for use, in other places.",
        "You are wholly responsible for use of the Site by any person using your computer and you must ensure that any such person complies with these Terms.",
      ],
    },
    warranties: {
      header: "No Warranties",
      body: [
        "The following clauses exclude or limit our legal liability for the Site. You should read them carefully. They all apply only as far as the law permits, and in particular we do not exclude or restrict our duties and liabilities to you under the regulations applicable to our business.",
        "While we have taken reasonable steps to ensure the accuracy, currency, availability correctness and completeness of the information contained on the Site, information is provided on an 'as is', 'as available' basis and we do not give or make any warranty or representation of any kind, whether express or implied. The use of the Site is at your sole risk. We shall not be liable for any loss or damage whatsoever and howsoever arising as a result of your use of or reliance on the information contained on the Site to the maximum extent permitted by law.",
        "We do not represent or warrant that the Site will be available and meet your requirements, that access will be uninterrupted, that there will be no delays, failures, errors or omissions or loss of transmitted information, that no viruses or other contaminating or destructive properties will be transmitted or that no damage will occur to your computer system. You have sole responsibility for adequate protection and back up of data and/or equipment and for undertaking reasonable and appropriate precautions to scan for computer viruses or other destructive properties.",
        "We make no representations or warranties regarding the accuracy, functionality or performance of any third party software that may be used in connection with the Site.",
      ],
    },
    endorsements: {
      header: "Third Party Sites and Endorsements",
      body: [
        "Where we provide hypertext links to other locations on the Internet, we do so for information purposes only. We are not responsible for the content of any other websites or pages linked from or linking to this Site. We have not verified the content of any such websites. Following links to any other websites or pages shall be at your own risk and we shall not be responsible or liable for any damages or in other way in connection with linking. Links to downloadable software sites are for convenience only and we are not responsible or liable for any difficulties or consequences associated with downloading the software. Use of any downloaded software is governed by the terms of the license agreement, if any, which accompanies or is provided with the software.",
        "No endorsement or approval of any third parties or their advice, opinions, information, products or services is expressed or implied by any information on our Site.",
      ],
    },
    trade: {
      header: "Trade Marks and Copyright",
      body: "Copyright in the pages screens, information and material in their arrangement included in this Site is owned by or licensed to Innaxys Ltd unless otherwise noted. You may imprint, copy, download or temporarily store extracts from our Site for your personal information or when you use our products and services. You must not alter anything. Any other use is prohibited unless you first get our written permission. In particular no one may use a part of our Site on any other website, or link any other website to our Site, without our prior written permission.",
    },
    internet: {
      header: "Internet E-mail",
      body: [
        "Messages sent over the Internet cannot be guaranteed to be completely secure as they are subject to possible interception or loss or possible alteration. We are not responsible for them and will not be liable to you or anyone else for any damages or otherwise in connection with any message sent by you to Innaxys or any message sent by Innaxys to you over the Internet.",
        "We maintain strict security standards and procedures to prevent unauthorised access to information about you. Innaxys will never contact you by email or otherwise to ask you to validate personal information.",
      ],
    },
    social: {
      header: "Social Media",
      body: [
        "Innaxys operates channels, pages and accounts on some social media sites to inform, assist and engage with customers. Unless stated otherwise, these are intended for UK customers. We monitor and record comments and posts made on these channels about Innaxys so that we can improve our services.",
        "Innaxys is not responsible for any information posted on those sites other than information we have posted ourselves. We do not endorse the social media sites themselves, or any information posted on them by third parties or other users.",
        "When you engage with Innaxys via social media your personal data may be stored on that social media site's servers, which are outside the control of Innaxys and may be in the US or elsewhere outside the EU. Any information posted to such sites may be accessed and read by the general public. It is not an appropriate forum to discuss customers' products or issues. Innaxys will not ask you to share any sensitive information via social media sites, and you should not post such information on those channels.",
        "Innaxys regularly updates and monitors our social media accounts and welcomes feedback and ideas submitted via these channels. Innaxys endeavours to join the conversation whenever possible, however cannot guarantee to read or reply to all messages sent to official Innaxys social media accounts.",
        "Emerging themes and helpful suggestions are passed to the relevant people within Innaxys for consideration, but no guarantee is made that any proposals will be acted upon.",
      ],
    },
    law: {
      header: "Governing Law",
      body: "The Terms are governed by and interpreted in accordance with the laws of England and Wales and the courts of the above jurisdiction will have non-exclusive jurisdiction in respect of any dispute, which may arise.",
    },
    queries: {
      header: "Your Queries",
      body: "If you wish to contact Innaxys Ltd, please refer to the Contact Us link which can be found on each page of the site.",
    },
  },
  fr: {
    title: "Déclaration de confidentialité et conditions",
    contents: {
      header: "Content",
      list: [
        "Policy",
        "Conditions du site",
        "Déclaration de confidentialité",
        "Information",
        "utilisateur du site",
        "Aucune garantie",
        "Sites et avals de tiers",
        "Marques et droits d'auteur",
        "Courriel Internet",
        "médias sociaux",
        "Droit applicable",
        "Vos questions",
      ],
    },
    textHead: [
      "Le présent document expose les conditions et la déclaration de confidentialité ('conditions') de innaxys.com et de tout sous-domaine (le 'site'), qui est exploité par Innaxys Ltd. En accédant à ce site, vous acceptez d'être lié par les conditions. Avant d'utiliser ce site, vous devez lire attentivement les conditions, ainsi que notre politique en matière de cookies ('Politique en matière de cookies').",
      "Innaxys Ltd est une société constituée en vertu des lois de l'Angleterre et du Pays de Galles sous le numéro d'enregistrement 7725890. Pour obtenir des informations sur la société, vous pouvez consulter le site Web de la Companies House.",
    ],
    provisions: {
      header: "Dans ces dispositions:",
      body: [
        "Le 'contenu' désigne l'ensemble des pages, écrans, informations et matériels inclus ou accessibles sur ce site (y compris tout contenu disponible sur les services de courrier électronique ou de SMS).",
        "Les termes 'vous', 'votre' et 'vos' désignent vous, la (les) personne(s) qui accède(nt) à ce site et la partie au nom de laquelle vous le faites.",
        "Les termes 'nous', 'notre' et 'nos' désignent Innaxys Ltd.",
        "'Innaxys' désigne Innaxys Ltd, ses filiales et ses sociétés associées de temps à autre.",
      ],
    },
    terms: {
      header: "Conditions d'utilisation du site",
      body: "Ces conditions couvrent votre utilisation de ce site. Tous les produits et services fournis par Innaxys ont leurs propres conditions (les 'conditions du produit'). Vous devez lire à la fois les conditions du produit et les présentes conditions. En cas de conflit entre les conditions du produit et les présentes conditions, les conditions du produit prévaudront.",
    },
    statement: {
      header: "Déclaration de confidentialité",
      body: [
        "La présente déclaration de confidentialité expose nos politiques actuelles et témoigne de notre engagement à respecter votre vie privée. Nous pouvons modifier le contenu ou les services de notre site à tout moment sans préavis, et par conséquent notre déclaration de confidentialité et les conditions peuvent être modifiées à tout moment à l'avenir. Vous devriez également lire notre politique en matière de cookies pour en savoir plus sur la façon dont Innaxys et nos partenaires de confiance utilisent les cookies, ce qui est pertinent pour votre sécurité en ligne : nous utilisons des cookies pour rendre notre site Web plus sûr et plus facile à utiliser.",
        "Innaxys Ltd fournit des solutions logicielles et des services pour la sécurité publique par l'intermédiaire de ses sociétés affiliées et de ses succursales situées dans le monde entier. Les principes de protection de la vie privée et des données personnelles varient d'un pays à l'autre. Lorsque vous accédez à un autre site web ou que vous vous y connectez, veuillez lire les déclarations ou accords de protection de la vie privée et des données publiés par cet autre site web afin de déterminer les politiques qui s'appliquent aux informations ou aux données conservées par ce site web.",
        "L'activité d'Innaxys s'est construite sur la confiance entre nous et nos clients.",
      ],
    },
    list: {
      header:
        "Préserver la confidentialité de toutes les informations que vous nous fournissez:",
      items: [
        "Innaxys ne recueillera que les informations qu'elle estime pertinentes et nécessaires pour comprendre les besoins commerciaux du client et pour mener à bien les activités d'Innaxys.",
        "Innaxys utilisera les informations relatives aux clients pour leur fournir de meilleurs services et produits.",
        "Innaxys peut transmettre des informations sur les clients à d'autres sociétés ou agents d'Innaxys, dans la mesure où la loi le permet.",
        "Innaxys ne divulguera pas d'informations sur les clients à une organisation externe, sauf si Innaxys a obtenu le consentement du client, si la loi l'exige ou si le client en a été informé au préalable.",
        "Innaxys peut être tenue, de temps à autre, de divulguer des informations sur ses clients à des organismes ou agences gouvernementaux ou judiciaires, mais Innaxys ne le fera qu'en vertu d'une autorité appropriée.",
        "Innaxys s'efforce de tenir à jour les informations relatives aux clients.",
        "Innaxys maintiendra des systèmes de sécurité stricts conçus pour empêcher l'accès non autorisé aux informations sur les clients par quiconque, y compris le personnel d'Innaxys.",
        "Toutes les sociétés et tous les employés d'Innaxys, ainsi que tous les tiers autorisés à accéder aux informations relatives aux clients, sont expressément tenus de respecter les obligations de confidentialité d'Innaxys.",
        "En maintenant notre engagement envers ces principes, nous nous assurons de respecter la confiance inhérente que vous accordez à Innaxys.",
      ],
    },
    info: {
      header: "Informations",
      body: [
        "Les informations ou opinions contenues dans ce site sont purement informatives et ne visent pas à guider un processus de décision.",
        "Si vous cherchez à vous fier de quelque manière que ce soit au contenu de ce site, vous le faites à vos propres risques.",
      ],
    },
    user: {
      header: "Utilisateur du site",
      body: [
        "Le site est actuellement destiné aux personnes qui y accèdent à partir du Royaume-Uni. Pour cette raison, nous ne pouvons pas garantir que le site ou les informations qu'il contient sont conformes à la législation ou à la réglementation, ou qu'ils peuvent être utilisés dans d'autres lieux.",
        "Vous êtes entièrement responsable de l'utilisation du site par toute personne utilisant votre ordinateur et vous devez vous assurer que cette personne respecte les présentes conditions.",
      ],
    },
    warranties: {
      header: "Aucune garantie",
      body: [
        "Les clauses suivantes excluent ou limitent notre responsabilité légale à l'égard du site. Vous devez les lire attentivement. Elles ne s'appliquent que dans la mesure où la loi le permet et, en particulier, nous n'excluons ni ne limitons nos obligations et nos responsabilités à votre égard en vertu de la réglementation applicable à notre activité.",
        "Bien que nous ayons pris des mesures raisonnables pour garantir l'exactitude, l'actualité, la disponibilité, la justesse et l'exhaustivité des informations contenues sur le site, celles-ci sont fournies 'en l'état' et 'en fonction des disponibilités' et nous ne donnons ni ne faisons aucune garantie ou déclaration de quelque nature que ce soit, expresse ou implicite. L'utilisation du site se fait à vos risques et périls. Nous ne pouvons être tenus responsables de toute perte ou de tout dommage, quel qu'il soit et quelle qu'en soit la cause, résultant de l'utilisation des informations contenues dans le site ou de la confiance accordée à ces informations, dans les limites autorisées par la loi.",
        "Nous ne déclarons ni ne garantissons que le site sera disponible et répondra à vos exigences, que l'accès sera ininterrompu, qu'il n'y aura pas de retards, de défaillances, d'erreurs ou d'omissions ou de pertes d'informations transmises, qu'aucun virus ou autre propriété contaminante ou destructrice ne sera transmis ou qu'aucun dommage ne sera causé à votre système informatique. Vous êtes seul responsable de la protection et de la sauvegarde adéquates des données et/ou de l'équipement, ainsi que de la prise de précautions raisonnables et appropriées pour détecter les virus informatiques ou autres propriétés destructrices.",
        "Nous ne faisons aucune déclaration ni ne donnons aucune garantie quant à l'exactitude, la fonctionnalité ou les performances des logiciels de tiers qui peuvent être utilisés en relation avec le site.",
      ],
    },
    endorsements: {
      header: "Sites de tiers et approbations",
      body: [
        "Lorsque nous fournissons des liens hypertextes vers d'autres sites Internet, nous le faisons uniquement à titre d'information. Nous ne sommes pas responsables du contenu des autres sites web ou pages liés à ce site. Nous n'avons pas vérifié le contenu de ces sites. La consultation de liens vers d'autres sites ou pages se fait à vos risques et périls et nous ne pouvons être tenus pour responsables de tout dommage ou autre lié à la consultation de ces liens. Les liens vers des sites de logiciels téléchargeables ne sont proposés que pour des raisons de commodité et nous ne sommes pas responsables des difficultés ou des conséquences liées au téléchargement du logiciel. L'utilisation de tout logiciel téléchargé est régie par les termes de l'accord de licence, le cas échéant, qui accompagne ou est fourni avec le logiciel.",
        "Aucune approbation de tiers ou de leurs conseils, opinions, informations, produits ou services n'est exprimée ou sous-entendue dans les informations figurant sur notre site.",
      ],
    },
    trade: {
      header: "Marques et droits d'auteur",
      body: "Les droits d'auteur des pages, des écrans, des informations et du matériel dans leur disposition inclus dans ce site sont détenus par Innaxys Ltd ou lui sont concédés sous licence, sauf indication contraire. Vous pouvez imprimer, copier, télécharger ou stocker temporairement des extraits de notre site pour votre information personnelle ou lorsque vous utilisez nos produits et services. Vous ne devez rien modifier. Toute autre utilisation est interdite sans notre autorisation écrite préalable. En particulier, personne ne peut utiliser une partie de notre site sur un autre site web, ou établir un lien entre un autre site web et notre site, sans notre autorisation écrite préalable.",
    },
    internet: {
      header: "Internet E-mail",
      body: [
        "La sécurité des messages envoyés sur Internet ne peut être garantie car ils sont susceptibles d'être interceptés, perdus ou modifiés. Nous n'en sommes pas responsables et nous ne serons pas responsables envers vous ou envers quiconque de tout dommage ou autre lié à un message que vous avez envoyé à Innaxys ou à un message qu'Innaxys vous a envoyé par l'intermédiaire d'Internet.",
        "Nous appliquons des normes et des procédures de sécurité strictes afin d'empêcher tout accès non autorisé aux informations vous concernant. Innaxys ne vous contactera jamais par courrier électronique ou autrement pour vous demander de valider des informations personnelles.",
      ],
    },
    social: {
      header: "Médias sociaux",
      body: [
        "Innaxys exploite des canaux, des pages et des comptes sur certains sites de médias sociaux afin d'informer, d'assister et d'engager les clients. Sauf indication contraire, ils sont destinés aux clients du Royaume-Uni. Nous surveillons et enregistrons les commentaires et les messages publiés sur ces canaux à propos d'Innaxys afin d'améliorer nos services.",
        "Innaxys n'est pas responsable des informations publiées sur ces sites autres que celles que nous avons publiées nous-mêmes. Nous n'approuvons pas les sites de médias sociaux eux-mêmes, ni les informations qui y sont publiées par des tiers ou d'autres utilisateurs.",
        "Lorsque vous vous engagez avec Innaxys via les médias sociaux, vos données personnelles peuvent être stockées sur les serveurs de ce site de médias sociaux, qui échappent au contrôle d'Innaxys et peuvent se trouver aux États-Unis ou ailleurs en dehors de l'UE. Toute information publiée sur ces sites peut être consultée et lue par le grand public. Il ne s'agit pas d'un forum approprié pour discuter des produits ou des problèmes des clients. Innaxys ne vous demandera pas de partager des informations sensibles via les sites de médias sociaux, et vous ne devriez pas publier de telles informations sur ces canaux.",
        "Innaxys met régulièrement à jour et surveille ses comptes de médias sociaux et accueille favorablement les commentaires et les idées soumis par le biais de ces canaux. Innaxys s'efforce de participer à la conversation dans la mesure du possible, mais ne peut garantir de lire ou de répondre à tous les messages envoyés aux comptes officiels de médias sociaux d'Innaxys.",
        "Les thèmes émergents et les suggestions utiles sont transmis aux personnes concernées au sein d'Innaxys pour examen, mais aucune garantie n'est donnée quant à la suite qui sera donnée aux propositions.",
      ],
    },
    law: {
      header: "Droit applicable",
      body: "Les conditions sont régies et interprétées conformément aux lois de l'Angleterre et du Pays de Galles et les tribunaux de la juridiction susmentionnée auront une compétence non exclusive en ce qui concerne tout litige qui pourrait survenir.",
    },
    queries: {
      header: "Vos questions",
      body: "Si vous souhaitez contacter Innaxys Ltd, veuillez vous référer au lien 'Nous contacter' qui se trouve sur chaque page du site.",
    },
  },
};
