import CardImage from "../assets/productCardImages/pCardImage.svg";

export const productCardTranslations = {
  en: {
    dams: {
      header: "Digital Asset Management System",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
      image: CardImage,
      ctaButton: "Book a demo"
    },
    tram: {
      header: "Training & Asset Management System",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
      image: CardImage,
      ctaButton: "Book a demo"
    },
    gis: {
      header: "GIS & Patrol Management System",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
      image: CardImage,
      ctaButton: "Book a demo"
    },
  },
  fr: {
    dams: {
      header: "Système de gestion des actifs numériques",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
      image: CardImage,
      ctaButton: "Réserver une démo"
    },
    tram: {
      header: "Système de formation et de gestion des actifs",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
      image: CardImage,
      ctaButton: "Réserver une démo"
    },
    gis: {
      header: "Système d'information géographique et de gestion des patrouilles",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
      image: CardImage,
      ctaButton: "Réserver une démo"
    },
  },
};
