export const damsTranslations = {
  en: {
    damsHeader: "DEAMS - Digital Evidence & Asset Management Solution",
    damsHeaderButton: "Request a demo",
    damsSubheading: "DEAMS provides a unique way to gather, analyse and share digital intelligence",
    text: [
      "The DEAMS Digital Asset Management Solution is a comprehensive, cloud-based eco-system comprising a suite of innovative modules for the end-to-end management of digital assets, evidence and files. DAMS was developed in conjunction with a major UK Police Force but is flexible enough for use by any organisation wishing to upload, store, analyse, manage and share their digital assets and files.",
      "It incorporates a suite of advanced editing and analytics tools, as well as the ability to control and manage all available metadata and retention/deletion requirements, with functionality to securely share the images and files with other users, teams or organisations.",
      "DAMS can be hosted on-premise, providing a private cloud environment, or on a secure server environment, set up and managed by ourselves and our hosting partners.",
    ]
  },
  fr: {
    damsHeader: "Solution de gestion des actifs numériques",
    damsHeaderButton: "Réserver une démo",
    damsSubheading: "Le DEAMS offre un moyen unique de recueillir, d'analyser et de partager l'intelligence numérique.",
    text: [
      "La solution de gestion des ressources numériques DEAMS est un écosystème complet, basé sur l'informatique en nuage, comprenant une suite de modules innovants pour la gestion de bout en bout des ressources numériques, des preuves et des fichiers. DAMS a été développé en collaboration avec une importante force de police britannique, mais il est suffisamment souple pour être utilisé par toute organisation souhaitant télécharger, stocker, analyser, gérer et partager ses actifs et fichiers numériques.",
      "Il intègre une série d'outils d'édition et d'analyse avancés, ainsi que la possibilité de contrôler et de gérer toutes les métadonnées disponibles et les exigences en matière de conservation/suppression, avec une fonctionnalité permettant de partager en toute sécurité les images et les fichiers avec d'autres utilisateurs, équipes ou organisations.",
      "Le DAMS peut être hébergé sur place, dans un environnement de nuage privé, ou sur un serveur sécurisé, mis en place et géré par nous-mêmes et nos partenaires d'hébergement.",
    ]
  },
}