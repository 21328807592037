export const carbonReductionTranslations = {
  en: {
    header: "Carbon Reduction Plan 2023 - 2024",
    subHeader: "Last updated: August 2023",
    pdfButton: "Download as PDF",
    return: "Back to overview",
    contentHeader: "Contents",
    contents: [
      "Commitment to achieving Net Zero",
      "Baseline Emissions Footprint",
      "Current Emissions Reporting",
      "Method Statement",
      "Carbon Reduction Projects",
    ],
    commitment: {
      header: "Commitment to achieving Net Zero",
      text: "Innaxys is committed to achieving Net Zero greenhouse gas emissions by 2027.",
    },
    baseline: {
      header: "Baseline Emissions Footprint",
      text: "Baseline emissions are a record of the greenhouse gases that have been produced in the past and were produced prior to the introduction of any strategies to reduce emissions. Baseline emissions are the reference point against which emissions reduction can be measured.",
      subHeader: "2022 Baseline",
      subText:
        "This report constitutes our baseline year for our corporate reporting and our journey to Net Carbon Zero within five years, by 2027. Due to a number of factors, we believe we are already starting from a low baseline, when compared to other similar companies in the same industry. However, this does not reduce our commitment as a company to ensure we continue to improve, and to our stated objective to achieve Net Zero.",
    },
    baselineTableData: [
      {
        header: "Baseline",
        headers: ["Scope One", "Scope Two", "Scope Three"],
      },
      {
        value: "2.0",
      },
      {
        value: "4.8",
      },
      {
        value: "2.7",
      },
      {
        total: "9.5",
      },
    ],
    currentEmissionsHeader: "Current Emissions Reporting",
    emissionsTableData: [
      {
        header: "Emissions",
        headers: ["Scope One", "Scope Two", "Scope Three"],
      },
      {
        value: "2.0",
      },
      {
        value: "4.8",
      },
      {
        value: "2.7",
      },
      {
        total: "9.5",
      },
    ],
    methods: {
      header: "Method Statement",
      text: [
        "Innaxys is committed to operating in a sustainable and planet friendly manner, and we proactively take steps to reduce our impact on the local, regional and global environment as we work towards net zero greenhouse gas emissions target.",
        "Whilst we have not previously measured our impact explicitly, we believe that our carbon footprint is already very low. Indeed, our footprint has already reduced significantly in recent years in part due to low office occupancy and reduced travel caused by COVID-19. We remain confident that our current and future measures will enable us to continue to reduce our impact and achieve net zero by, if not ahead of, our target of 2027.",
        "The increased acceptance by our clients of the use of video conferencing, (another side effect of COVID-19 restrictions) has reduced our travel expenditure and impact by over 75%. Over 60% of our staff live within walking/cycling distance of the offices, and we also allow extensive working from home, again, minimising the additional footprint of our employees. As a software development company, we naturally do not have manufacturing or assembly activities with extensive power requirements. We also do not have a supply chain as such, with all our Solutions being software based, developed and delivered on site at our offices. We do not use subcontractors or third-party suppliers for the delivery of our core software Solutions. Where hardware elements or equipments are occasionally requested of us as part of a contract, we source these in the most efficient ways possible including choosing slower, bulk postal deliveries rather than express options, click & collect services, and usage of local suppliers that require minimum travel for either party to collect or deliver. We also ensure that we are working with suppliers who have published and are upholding their own environmental policies and pledges to achieve net zero greenhouse gas emissions substantially ahead of the 2050 deadline as set out by the UK government in law in 2019.",
        "Our server room is installed in such a way that it does not require or utilise air conditioning. We use natural ventilation throughout our office building and have installed energy efficient heat and lighting wherever possible.",
        "Our current calculation of our carbon footprint results in a number well below the national average, at between 7 and 10 tonnes of CO2 per year.",
        "In order to continue our progress to achieving Net Zero, we have adopted the following carbon reduction targets, and others are actively being sought and investigated:",
      ],
      list: [
        "We will build awareness amongst our workforce of the impact of their decisions on our journey to Net Zero, including running a communications campaign to educate and drive awareness around events such as World Environment Day.",
        "We will actively quantify carbon emissions from commuting and working from home via a rolling employee survey and use this as an opportunity to decrease scope 3 emissions via employee engagement. We actively encourage all staff to consider green and eco-friendly transport for commuting to work (and in general).",
        "When replacing cars used for company business (we do not provide company cars, or pool cars) staff will be encouraged to consider electric vehicles or more environmentally friendly options.",
        "We will assess our energy supply and consider a move to a supplier that demonstrates a full commitment to supplying 100% Green Energy when our current contract runs out.",
        "We will work to become a paperless office by the end of 2023",
        "Actively evaluate a number of carbon offset schemes available through the website https://www.carbonfootprint.com",
      ],
      followingText: [
        "As a senior management team, we have committed to reviewing our carbon footprint and performance and progress towards our stated target within our quarterly management meetings, and to have a standing item on our monthly team meeting agenda.",
        "The Carbon Reduction Plan will be updated as a minimum annually. We will provide additional figures and graphs as appropriate within this Carbon Reduction Plan document to demonstrate our actual and projected reductions.",
      ],
    },
    reductionProjects: {
      header: "Carbon Reduction Projects",
      text: "The following environmental management measures and projects have been completed or implemented since the 2022 baseline. The carbon emission reductions achieved by these schemes will be calculated and we will publish the percentages detailing the reduction against the baseline within this document, as soon as we have enough data to generate meaningful reports. The following measures are currently in effect in order to deliver additional environmental benefits in the performance of all our contracts:",
      subheadings: [
        "Remote Working",
        "Virtual Meetings",
        "Paperless Document Sharing",
      ],
      subText: [
        "All of our staff are provided with the means to maximise their use of file-sharing, instant messaging and video conferencing for communicating with each other whilst working remotely, reducing the need for them to travel into the office every day. Our engineers all have full access to our secure online web portals for software development and management, which enables them to collaborate on projects easily. The provision complies with our ISO27001 certification as our engineers all use company laptops and equipment, accessing our systems via secure VPN into our servers.",
        "Since before the COVID19 pandemic, Innaxys has routinely promoted the use of online virtual meetings, having used these extensively since 2015 to deliver our Training Management Solution to the Police Service of Northern Ireland, in working with a French partner organisation to deliver the same system to the Irish Fire Service, in the delivery of a Firearms Asset Management Solution to the Metropolitan Police, and more recently almost exclusively in the delivery of a Digital Asset Management Solution to the Northern Ireland Civil Service. We will conduct as many meetings as possible in this way, reducing the need for us to travel to your offices, although this will always be balanced against the need and purpose of each meeting, and we will, where relevant, necessary, or unavoidable, attend on site.",
        "We use online document sharing services such as DropBox and online Project Management Portals to reduce the amount of printed material used within the company and with clients. All project related documents will be available electronically in a version controlled, auditable environment, accessible by all stakeholders at all times.",
      ],
    },
    training: {
      header: "Training",
      text: [
        "We have re-structured our training activities to minimise the amount of on-site training that is required by each new deployment, and we offer all our Solution training as virtual sessions (although face to face is always still available where appropriate/preferred by the organisation), or a mixture of both, depending on the nature of the roles being trained for. Users are provided access to the Training version of the Solution in order that they can begin to become familiar with, and practice on, the use of the Solution either in advance of, or as a minimum after training and before Go Live, but then also going forward after Go-Live as a safe place to practice any areas they are unsure of, to refresh their knowledge or otherwise train on the system throughout the contract.",
        "We have also created specific e-learning modules for each Solution, which we tailor specifically to the needs of each organisation (as defined and agreed in the Training Needs Analysis) and which we make available within our own version of our e-learning Solution, hosted in our offices and available to users over the internet. ",
        "Finally, we have created a number of short ‘how-to’ videos and step-by-step training sheets on specific elements of our Solution which will be made available to users. These are utilised for the less complicated user roles and occasional users, who have defined and ‘simple’ access to the system, and usually serve to minimise the formal face to face training required by the largest volume of users.",
      ],
    },
    office: {
      header: "Office",
      text: [
        "In addition to project specific provisions, Innaxys as a company has instigated the following measures as standard across our operations for many years:",
        "We provide recycling containers in every office to ensure all staff are able to recycle their waste as well as all packaging from deliveries, where appropriate. We work with our local council, who provide suitable receptacles and a removal service for all our recycling waste.",
        "We use smart plugs and time sensors to reduce our consumption of electricity, with energy efficient lightbulbs installed in all areas.",
        "We have recently replaced the boiler controls on our office heating, installing a Hive system to give us greater control and efficiency over the heating of the building, with differentiated areas so that we can reduce heating in areas of low use whilst maintaining heat in other specific areas when required.",
        "We provide facilities for our staff to shower and change, in order for them to walk, run or cycle to work, and have actually sited our new offices very locally to where the majority of our staff live, rather than in a city centre necessitating the use of cars or public transport to travel to work.",
        "We have installed water fountains to provide fresh filtered drinking water from large containers and encourage staff to bring their own glass or organic material water bottle to fill up. We provide coffee, tea, sugar, etc. from catering size packaging, again reducing the overall amount of packaging coming into the business, and we do not use single-use kitchenware, providing instead ceramic crockery and cups.",
        "Our office itself is a reclaimed and refurbished industrial unit, which we brought back into full use in 2019. During the refurbishment we ensured we supported local businesses by exclusively employing local tradesmen, with instruction to use recycled or reclaimed materials, or eco-friendly options during the fit-out.",
      ],
    },
    signOff: {
      header: "Declaration and Sign Off",
      text: [
        "This Carbon Reduction Plan has been completed in accordance with PPN 06/21 and associated guidance and reporting standard for Carbon Reduction Plans.",
        "Emissions have been reported and recorded in accordance with the published reporting standard for Carbon Reduction Plans and the GHG Reporting Protocol corporate standard1 and uses the appropriate Government emission conversion factors for greenhouse gas company reporting.",
        "Scope 1 and Scope 2 emissions have been reported in accordance with SECR requirements, and the required subset of Scope 3 emissions have been reported in accordance with the published reporting standard for Carbon Reduction Plans and the Corporate Value Chain (Scope 3) Standard.",
        "This Carbon Reduction Plan has been reviewed and signed off by the board of directors (or equivalent management body).",
      ],
    },
    signiture: {
      position: "Commercial Lead",
      date: "August 2023",
    },
  },
  fr: {
    header: "Plan de réduction du carbone 2023 - 2024",
    subHeader: "Dernière mise à jour : Août 2023",
    pdfButton: "Télécharger en PDF",
    return: "Retour à l'aperçu",
    contentHeader: "Contenu",
    contents: [
      "Engagement à atteindre l'objectif 'Net Zero",
      "Empreinte des émissions de référence",
      "Rapports actuels sur les émissions",
      "Déclaration de méthode",
      "Projets de réduction des émissions de carbone",
    ],
    commitment: {
      header: "Engagement à atteindre l'objectif 'net zéro",
      text: "Innaxys s'est engagée à réduire à zéro ses émissions de gaz à effet de serre d'ici à 2027.",
    },
    baseline: {
      header: "Empreinte des émissions de référence",
      text: "Les émissions de référence sont un enregistrement des gaz à effet de serre qui ont été produits dans le passé et qui ont été produits avant l'introduction de toute stratégie visant à réduire les émissions. Les émissions de référence constituent le point de référence par rapport auquel la réduction des émissions peut être mesurée.",
      subHeader: "2022 Base de référence",
      subText:
        "Le présent rapport constitue l'année de référence pour nos rapports d'entreprise et notre parcours vers l'objectif 'zéro carbone' en cinq ans, d'ici à 2027. En raison d'un certain nombre de facteurs, nous pensons que nous partons déjà d'une base peu élevée par rapport à d'autres entreprises similaires dans le même secteur. Toutefois, cela ne diminue en rien notre engagement en tant qu'entreprise à continuer à nous améliorer et à atteindre notre objectif déclaré de parvenir à un bilan net zéro.",
    },
    baselineTableData: [
      {
        header: "Base de référence",
        headers: ["Scope One", "Scope Two", "Scope Three"],
      },
      {
        value: "2.0",
      },
      {
        value: "4.8",
      },
      {
        value: "2.7",
      },
      {
        total: "9.5",
      },
    ],
    currentEmissionsHeader: "Rapports actuels sur les émissions",
    emissionsTableData: [
      {
        header: "Émissions",
        headers: ["Scope One", "Scope Two", "Scope Three"],
      },
      {
        value: "1.0",
      },
      {
        value: "0.8",
      },
      {
        value: "2.1",
      },
      {
        total: "3.9",
      },
    ],
    methods: {
      header: "Déclaration de méthode",
      text: [
        "Innaxys s'engage à fonctionner de manière durable et respectueuse de la planète, et nous prenons des mesures proactives pour réduire notre impact sur l'environnement local, régional et mondial, tout en nous efforçant d'atteindre l'objectif de zéro émission nette de gaz à effet de serre.",
        "Bien que nous n'ayons pas encore mesuré notre impact de manière explicite, nous pensons que notre empreinte carbone est déjà très faible. En effet, notre empreinte s'est déjà considérablement réduite ces dernières années, en partie grâce au faible taux d'occupation de nos bureaux et à la diminution des déplacements occasionnés par le COVID-19. Nous restons persuadés que nos mesures actuelles et futures nous permettront de continuer à réduire notre impact et d'atteindre le niveau zéro net d'ici à 2027, si ce n'est avant.",
        "L'acceptation croissante par nos clients de l'utilisation de la vidéoconférence (un autre effet secondaire des restrictions COVID-19) a permis de réduire nos dépenses et notre impact sur les déplacements de plus de 75 %. Plus de 60 % de notre personnel vit à proximité de nos bureaux, à pied ou à vélo, et nous autorisons également le travail à domicile, ce qui minimise encore l'empreinte supplémentaire de nos employés. En tant qu'entreprise de développement de logiciels, nous n'avons naturellement pas d'activités de fabrication ou d'assemblage nécessitant une grande quantité d'énergie. Nous n'avons pas non plus de chaîne d'approvisionnement en tant que telle, toutes nos solutions étant basées sur des logiciels, développées et livrées sur place dans nos bureaux. Nous ne faisons pas appel à des sous-traitants ou à des fournisseurs tiers pour la fourniture de nos solutions logicielles de base. Lorsque des éléments matériels ou des équipements nous sont occasionnellement demandés dans le cadre d'un contrat, nous les achetons de la manière la plus efficace possible, notamment en choisissant des livraisons postales plus lentes et en vrac plutôt que des options express, des services 'click & collect' et en recourant à des fournisseurs locaux qui nécessitent un minimum de déplacements pour l'une ou l'autre partie afin de collecter ou de livrer le matériel. Nous veillons également à travailler avec des fournisseurs qui ont publié et respectent leurs propres politiques et engagements en matière d'environnement afin de parvenir à des émissions nettes de gaz à effet de serre nulles bien avant l'échéance de 2050 fixée par le gouvernement britannique dans la loi de 2019.",
        "Notre salle des serveurs est installée de telle sorte qu'elle ne nécessite ni n'utilise d'air conditionné. Nous utilisons la ventilation naturelle dans l'ensemble de nos bureaux et avons installé des systèmes de chauffage et d'éclairage à faible consommation d'énergie dans la mesure du possible.",
        "Le calcul actuel de notre empreinte carbone donne un chiffre bien inférieur à la moyenne nationale, entre 7 et 10 tonnes de CO2 par an.",
        "Afin de poursuivre notre progression vers l'objectif 'Net Zero', nous avons adopté les objectifs de réduction des émissions de carbone suivants, et d'autres sont activement recherchés et étudiés :",
      ],
      list: [
        "Nous sensibiliserons notre personnel à l'impact de ses décisions sur notre parcours vers le Net Zero, notamment en menant une campagne de communication pour éduquer et sensibiliser autour d'événements tels que la Journée mondiale de l'environnement",
        "Nous quantifierons activement les émissions de carbone dues aux déplacements domicile-travail et au travail à domicile par le biais d'une enquête permanente auprès des employés, et nous en profiterons pour réduire les émissions de type 3 grâce à l'engagement des employés. Nous encourageons activement l'ensemble du personnel à opter pour des moyens de transport verts et écologiques pour se rendre au travail (et en général).",
        "Lors du remplacement des voitures utilisées pour les besoins de l'entreprise (nous ne fournissons pas de voitures de fonction, ni de voitures de pool), le personnel sera encouragé à envisager des véhicules électriques ou des options plus respectueuses de l'environnement.",
        "Nous évaluerons notre approvisionnement en énergie et envisagerons de passer à un fournisseur qui s'engage pleinement à fournir de l'énergie 100 % verte lorsque notre contrat actuel arrivera à échéance.",
        "Nous nous efforcerons de devenir un bureau sans papier d'ici à la fin de l'année 2023.",
        "Évaluer activement un certain nombre de programmes de compensation des émissions de carbone disponibles sur le site web https://www.carbonfootprint.com.",
      ],
      followingText: [
        "En tant qu'équipe de direction, nous nous sommes engagés à examiner notre empreinte carbone et nos performances, ainsi que les progrès réalisés pour atteindre l'objectif fixé, dans le cadre de nos réunions de direction trimestrielles, et à inscrire un point permanent à l'ordre du jour de nos réunions d'équipe mensuelles.",
        "Le plan de réduction des émissions de carbone sera mis à jour au moins une fois par an. Nous fournirons des chiffres et des graphiques supplémentaires, le cas échéant, dans le cadre de ce plan de réduction des émissions de carbone, afin de démontrer nos réductions réelles et prévues.",
      ],
    },
    reductionProjects: {
      header: "Projets de réduction du carbone",
      text: "Les mesures et projets de gestion environnementale suivants ont été achevés ou mis en œuvre depuis l'année de référence 2022. Les réductions d'émissions de carbone obtenues grâce à ces projets seront calculées et nous publierons les pourcentages détaillant la réduction par rapport à la base de référence dans ce document, dès que nous aurons suffisamment de données pour générer des rapports significatifs. Les mesures suivantes sont actuellement en vigueur afin d'obtenir des avantages environnementaux supplémentaires dans le cadre de l'exécution de tous nos contrats:",
      subheadings: [
        "Travail à distance",
        "Réunions virtuelles",
        "Partage de documents sans papier",
      ],
      subText: [
        "Tous les membres de notre personnel disposent des moyens de maximiser leur utilisation du partage de fichiers, de la messagerie instantanée et de la vidéoconférence pour communiquer entre eux lorsqu'ils travaillent à distance, ce qui réduit la nécessité pour eux de se rendre au bureau tous les jours. Nos ingénieurs ont tous un accès complet à nos portails web sécurisés en ligne pour le développement et la gestion de logiciels, ce qui leur permet de collaborer facilement sur des projets. Cette disposition est conforme à notre certification ISO27001, car nos ingénieurs utilisent tous des ordinateurs portables et des équipements de l'entreprise, et accèdent à nos systèmes via un réseau privé virtuel (VPN) sécurisé sur nos serveurs.",
        "Depuis avant la pandémie de COVID19, Innaxys a régulièrement encouragé l'utilisation de réunions virtuelles en ligne, les ayant largement utilisées depuis 2015 pour fournir notre solution de gestion de la formation aux services de police d'Irlande du Nord, en travaillant avec une organisation partenaire française pour fournir le même système aux services de pompiers irlandais, pour fournir une solution de gestion des actifs d'armes à feu à la police métropolitaine, et plus récemment presque exclusivement pour fournir une solution de gestion des actifs numériques à l'administration publique d'Irlande du Nord. Nous organiserons autant de réunions que possible de cette manière, réduisant ainsi la nécessité de nous rendre dans vos bureaux, bien que cela soit toujours mis en balance avec la nécessité et l'objectif de chaque réunion, et nous assisterons, le cas échéant, si nécessaire ou inévitable, à la réunion sur place.",
        "Nous utilisons des services de partage de documents en ligne tels que DropBox et des portails de gestion de projet en ligne afin de réduire la quantité de documents imprimés utilisés au sein de l'entreprise et avec les clients. Tous les documents relatifs au projet seront disponibles électroniquement dans un environnement contrôlé par version et vérifiable, accessible par toutes les parties prenantes à tout moment.",
      ],
    },
    training: {
      header: "Formation",
      text: [
        "Nous avons restructuré nos activités de formation afin de minimiser la quantité de formation sur site requise pour chaque nouveau déploiement, et nous proposons toutes nos formations à la solution sous forme de sessions virtuelles (bien qu'une formation en face à face soit toujours disponible lorsque cela est approprié/préféré par l'organisation), ou un mélange des deux, en fonction de la nature des rôles pour lesquels la formation est dispensée. Les utilisateurs ont accès à la version de formation de la solution afin qu'ils puissent commencer à se familiariser et à s'exercer à l'utilisation de la solution, soit avant, soit au minimum après la formation et avant la mise en service, mais aussi après la mise en service, en tant qu'endroit sûr pour s'exercer dans les domaines où ils ne sont pas sûrs, pour rafraîchir leurs connaissances ou pour s'entraîner au système tout au long du contrat.",
        "Nous avons également créé des modules d'apprentissage en ligne spécifiques pour chaque solution, que nous adaptons aux besoins de chaque organisation (tels que définis et convenus dans l'analyse des besoins de formation) et que nous mettons à disposition dans notre propre version de notre solution d'apprentissage en ligne, hébergée dans nos bureaux et accessible aux utilisateurs via Internet.",
        "Enfin, nous avons créé un certain nombre de courtes vidéos 'comment faire' et de fiches de formation étape par étape sur des éléments spécifiques de notre solution, qui seront mises à la disposition des utilisateurs. Celles-ci sont utilisées pour les rôles d'utilisateurs moins complexes et les utilisateurs occasionnels, qui ont un accès défini et 'simple' au système, et servent généralement à minimiser la formation formelle en face à face requise par le plus grand nombre d'utilisateurs.",
      ],
    },
    office: {
      header: "Bureau",
      text: [
        "Outre les dispositions spécifiques au projet, Innaxys, en tant qu'entreprise, a mis en place les mesures suivantes en tant que norme dans ses opérations depuis de nombreuses années:",
        "Nous disposons de conteneurs de recyclage dans chaque bureau afin que tous les employés puissent recycler leurs déchets ainsi que tous les emballages des livraisons, le cas échéant. Nous travaillons avec notre municipalité, qui fournit des récipients appropriés et un service d'enlèvement pour tous nos déchets recyclables.",
        "Nous utilisons des prises intelligentes et des détecteurs de temps pour réduire notre consommation d'électricité, et des ampoules à faible consommation d'énergie sont installées dans toutes les zones.",
        "Nous avons récemment remplacé les commandes de la chaudière pour le chauffage de nos bureaux, en installant un système Hive qui nous donne un meilleur contrôle et une plus grande efficacité sur le chauffage du bâtiment, avec des zones différenciées qui nous permettent de réduire le chauffage dans les zones peu utilisées tout en maintenant la chaleur dans d'autres zones spécifiques lorsque c'est nécessaire.",
        "Nous mettons à la disposition de notre personnel des installations pour se doucher et se changer, afin qu'il puisse se rendre au travail à pied, en courant ou à vélo, et nous avons en fait installé nos nouveaux bureaux très près du lieu de résidence de la majorité de notre personnel, plutôt que dans un centre ville nécessitant l'utilisation de voitures ou de transports en commun pour se rendre au travail.",
        "Nous avons installé des fontaines à eau pour fournir de l'eau potable fraîche et filtrée dans de grands récipients et nous encourageons le personnel à apporter sa propre bouteille d'eau en verre ou en matériau organique pour la remplir. Nous fournissons du café, du thé, du sucre, etc. dans des emballages de format restauration, ce qui réduit encore la quantité totale d'emballages entrant dans l'entreprise, et nous n'utilisons pas d'ustensiles de cuisine à usage unique, mais de la vaisselle et des tasses en céramique.",
        "Notre bureau lui-même est une unité industrielle récupérée et rénovée, que nous avons remise en service en 2019. Lors de la rénovation, nous avons veillé à soutenir les entreprises locales en employant exclusivement des artisans locaux et en leur demandant d'utiliser des matériaux recyclés ou récupérés, ou des options écologiques lors de l'aménagement.",
      ],
    },
    signOff: {
      header: "Déclaration et signature",
      text: [
        "Ce plan de réduction des émissions de carbone a été réalisé conformément à la NPP 06/21, aux orientations associées et à la norme de rapport pour les plans de réduction des émissions de carbone.",
        "Les émissions ont été déclarées et enregistrées conformément à la norme de déclaration publiée pour les plans de réduction des émissions de carbone et à la norme d'entreprise du protocole de déclaration des GES1 , et utilisent les facteurs de conversion des émissions appropriés du gouvernement pour la déclaration des gaz à effet de serre par les entreprises.",
        "Les émissions des champs d'application 1 et 2 ont été déclarées conformément aux exigences de la SECR, et le sous-ensemble requis d'émissions du champ d'application 3 a été déclaré conformément à la norme de déclaration publiée pour les plans de réduction du carbone et la norme de la chaîne de valeur de l'entreprise (champ d'application 3).",
        "Le présent plan de réduction des émissions de carbone a été examiné et approuvé par le conseil d'administration (ou l'organe de direction équivalent).",
      ],
    },
    signiture: {
      position: "Chef commercial",
      date: "Août 2023",
    },
  },
};
