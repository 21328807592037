export const landingTranslations = {
  en: {
    header: '"The best way to predict the future is to invent it"',
    paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non. Pellentesque nec malesuada dui. Ut dapibus lorem tincidunt erat luctus lobortis. Aenean nec ullamcorper tortor. Sed suscipit felis vitae dui lacinia porttitor.",
    ctaButton: "Contact Us",
    softwareSolutions: "Software solutions for",
    solutionsContext: "Innaxys prides itself on developing software solutions that help police forces and other public safety agencies deliver a better service to the community, more efficiently and with the best intelligence in hand. We use science, experience and advanced technologies to develop complete solutions to support officers in the street with mobile technology, as well as management teams and analysts through cloud-based applications. Our solutions enable our clients to operate new intelligence-led models of policing, with less back-office effort while delivering better service to the community.",
    drivingSeat: "Putting users in the driving seat",
    drivingSeatText1: "We are committed to the public safety market as demonstrated by our large investment in research & development. Through collaboration with academics and practitioners we ensure that users are put in the driving seat of all the solutions we develop.",
    drivingSeatText2: "Science is a large contributor to our solutions and we encourage our people to keep up-to-date with technology as well as domains such as mathematics and crime-science.",
    evidence: "Evidence based and predictive policing",
    evidenceContext: "Innaxys invests significant resources into the research & development of new evidence-based and predictive policing solutions. Our Excalibur suite uses crime science and police knowledge to predict the future and support a proactive tasking and briefing model."
  },
  fr: {
    header: "Le meilleur moyen de prédire l'avenir est de l'inventer",
    paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non. Pellentesque nec malesuada dui. Ut dapibus lorem tincidunt erat luctus lobortis. Aenean nec ullamcorper tortor. Sed suscipit felis vitae dui lacinia porttitor.",
    ctaButton: "Contactez nous",
    softwareSolutions: "Solutions logicielles pour",
    solutionsContext: "Innaxys est fière de développer des solutions logicielles qui aident les forces de police et les autres organismes de sécurité publique à fournir un meilleur service à la communauté, de manière plus efficace et avec les meilleurs renseignements en main. Nous utilisons la science, l'expérience et les technologies de pointe pour développer des solutions complètes qui soutiennent les agents dans la rue grâce à la technologie mobile, ainsi que les équipes de gestion et les analystes grâce à des applications basées sur le nuage.",
    drivingSeat: "Placer les utilisateurs aux commandes",
    drivingSeatText1: "Nous sommes engagés sur le marché de la sécurité publique, comme en témoigne notre important investissement dans la recherche et le développement. En collaborant avec des universitaires et des praticiens, nous veillons à ce que les utilisateurs soient au cœur de toutes les solutions que nous développons.",
    drivingSeatText2: "La science contribue largement à nos solutions et nous encourageons notre personnel à se tenir au courant des technologies ainsi que des domaines tels que les mathématiques et les sciences criminelles.",
    evidence: "Police fondée sur des preuves et prédictive",
    evidenceContext: "Innaxys investit des ressources importantes dans la recherche et le développement de nouvelles solutions policières basées sur des preuves et prédictives. Notre suite Excalibur utilise la science criminelle et les connaissances de la police pour prédire l'avenir et soutenir un modèle proactif d'attribution des tâches et de briefing.",
  },
};
