import React from "react";
import { TbRoute } from "react-icons/tb";
import IndustryFeature from "./IndustryFeature";

function IndustryFeatures() {
  const industryFeaturesData = [
    {
      icon: <TbRoute size={30} />,
      header: "Route Planning 1",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
    },
    {
      icon: <TbRoute size={30} />,
      header: "Route Planning 2",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
    },
    {
      icon: <TbRoute size={30} />,
      header: "Route Planning 3",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
    },
    {
      icon: <TbRoute size={30} />,
      header: "Route Planning 4",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
    },
    {
      icon: <TbRoute size={30} />,
      header: "Route Planning 5",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
    },
    {
      icon: <TbRoute size={30} />,
      header: "Route Planning 6",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non.",
    },
  ];

  return (
    <>
    <div className="industry-features-container">
      <div className="industry-features-header">
        <h4>Features</h4>
      </div>
      <div className="industry-features-wrapper">
        {industryFeaturesData.map((feature, index) => (
          <IndustryFeature key={index} data={feature} />
        ))}
      </div>
    </div>
    </>
  );
}

export default IndustryFeatures;
