import React from "react";

const Table = ({ data }) => {
  const headers = data[0].headers || [];

  return (
    <div className="table-container">
      <div className="table-header">
        <span>{data[0].header}</span>
        <span>Total (tC02e)</span>
      </div>
      {data.slice(1, -1).map((row, index) => (
        <div className="table-row" key={index}>
          <span>{headers[index]}</span>
          <span>{Object.values(row)[0]}</span>
        </div>
      ))}
      <div className="table-footer">
        <span>Total Emissions</span>
        <span>{data[data.length - 1].total}</span>
      </div>
    </div>
  );
};

export default Table;
