import React from 'react';

function headerSection({ header, subHeader, buttonText, image, onClick, isLandingButton }) {
  const buttonClass = isLandingButton ? "cta-button-large" : "cta-button-large-white";

  return (
    <div className="landing-container">
      <div className="landing-section">
        <div className="landing-wrapper">
          <div data-aos="fade-right" className="landing-content">
            <h1 className="landing-header">{header}</h1>
            <p className="gray">{subHeader}</p>
            <button className={buttonClass} onClick={onClick}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <div className="landing-image">
        <img src={image} alt="Landing" />
      </div>
    </div>
  );
}

export default headerSection;
