export const vacanciesTranslations = {
  en: {
    header: "Current Vacancies",
    opening1: {
      title: "Graduate Software Developer – Android and Java",
      area: "Engineering",
      closingDate: "21/12/23",
    },
    opening2: {
      title: "Test and Support Engineer with a developer’s background ",
      area: "Engineering",
      closingDate: "21/12/23",
    },
    // opening3: {
    //   title: "Buisness Management Intern",
    //   area: "Business",
    //   closingDate: "21/12/23",
    // },
  },
  fr: {
    header: "Postes vacants",
    opening1: {
      title: "Développeur backend senior",
      area: "Ingénierie",
      closingDate: "21/12/23",
    },
    opening2: {
      title: "Développeur Frontend Junior",
      area: "Ingénierie",
      closingDate: "21/12/23",
    },
    // opening3: {
    //   title: "Stagiaire en gestion des affaires",
    //   area: "Entreprises",
    //   closingDate: "21/12/23",
    // },
  },
};