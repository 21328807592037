import React, { useEffect, useRef } from "react";
import Nav from "../components/Nav";
import { motion, useScroll } from "framer-motion";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../config/LanguageContext";
import Footer from "../components/Footer";
import FooterCertificates from "../components/FooterCertificates";

export const CookiePolicy = () => {
  const { scrollYProgress } = useScroll();
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();
  const sectionRefs = useRef([]);

  const navigateToResources = () => {
    navigate("/resources");
  };

  const scrollToSection = (index) => {
    const targetIndex = index - 1; // Subtract 1 to scroll to the previous section
    if (sectionRefs.current[targetIndex]) {
      const offset = -200; // Adjust this value as needed
      const sectionPosition =
        sectionRefs.current[targetIndex].getBoundingClientRect().top;
      window.scrollTo({
        top: window.pageYOffset + sectionPosition + offset,
        behavior: "smooth",
      });
    }
  };

  // Destructuring translations for easier access
  const { header, contents, intro, contexts } =
    translations.cookies[currentLanguage];

  useEffect(() => {
    const header = document.getElementById("myHeader");
    if (!header) return;

    const sticky = header.offsetTop;

    const handleScroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Nav />
      {/* Progress Bar */}
      <div className="progress-bar-container">
        <motion.div
          className="progress-bar"
          style={{
            scaleX: scrollYProgress,
            backgroundColor: "var(--primary)",
            height: "10px",
          }}
        />
      </div>

      {/* Cookie Policy Container */}
      <div className="resources-container">
        {/* Header */}
        <div className="resources-header-container">
          <div className="resources-wrapper">
            <h1 className="gray">{header}</h1>
          </div>
        </div>

        {/* Sticky Header */}
        <div className="header" id="myHeader">
          <div className="back-container" onClick={navigateToResources}>
            <div className="back-wrapper">
              <span className="back-to-overview">
                <IconArrowLeft size={20} />{" "}
                {translations.carbonReduction[currentLanguage].return}
              </span>
            </div>
          </div>
          {/* Progress Bar */}
          <div className="progress-bar-container">
            <motion.div
              className="progress-bar"
              style={{
                scaleX: scrollYProgress,
                backgroundColor: "var(--primary)",
                height: "10px",
              }}
            />
          </div>
        </div>

        {/* Contents */}
        <div className="resources-content-container">
          <div className="resources-contents-wrapper">
            <div className="resources-contents-container">
              <h2>{contents.header}</h2>
              <ul className="resources-contents-list">
                {contents.listItems.map((item, index) => (
                  <li
                    tabIndex={0}
                    key={index}
                    onClick={() => scrollToSection(index)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        scrollToSection(index);
                      }
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            {/* Body */}
            <div className="resources-body-container">
              {/* Introduction */}
              {intro.map((introText, index) => (
                <p key={index}>{introText}</p>
              ))}

              {/* Contexts */}
              {contexts.map((context, index) => (
                <div
                  className="resources-text-wrapper"
                  key={index}
                  ref={(el) => (sectionRefs.current[index] = el)}
                >
                  <h2>{context.header}</h2>
                  {Array.isArray(context.text) ? (
                    <div>
                      {context.text.map((text, idx) => (
                        <p key={idx}>{text}</p>
                      ))}
                    </div>
                  ) : (
                    <p>{context.text}</p>
                  )}
                  {context.textList && (
                    <ul className="resources-list">
                      {context.textList.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <FooterCertificates />
      <Footer />
    </div>
  );
};
