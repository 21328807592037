import React, { useState } from "react";
import {
  IconFolder,
  IconCalendarEvent,
  IconCalendar,
  IconCaretDownFilled,
} from "@tabler/icons-react";
import { useLanguage } from "../config/LanguageContext";

function GSDVacancyCard({ openingKey }) {
  const { currentLanguage, translations } = useLanguage();
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const vacancyData = translations.vacancies[currentLanguage][openingKey];

  return (
    <>
      <div
        className="vacancy-card-container"
        tabIndex={0}
        onClick={toggleActive}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            toggleActive();
          }
        }}
      >
        <div className="vacancy-card-wrapper">
          <div className="vacancy-details-wrapper">
            <h2 className="vacancy-card-title">
              Graduate Software Developer – Android and Java
            </h2>
            {/* <div className="vacancy-card-info-wrapper">
        <span className="vacancy-card-date" title="Closing Date">
          <IconCalendar />
          01/02/2024
        </span>
      </div> */}
          </div>
          <div className="caret-wrapper">
            <IconCaretDownFilled
              size={30}
              className={`caret ${isActive ? "active" : ""}`}
            />
          </div>
        </div>
        <div
          className={`vacancy-description ${isActive ? "visible" : "hidden"}`}
        >
          <div>
            <p>
              Most of our development is varied, with lots of cloud-based
              applications and their mobile companions but also sometimes some
              more exotic devs. We are looking to grow our team with people
              capable to develop complex and beautiful applications for public
              safety mostly. This application is opened to graduates with a good
              scientific background.{" "}
            </p>
            <ul>
              <span>What you will do</span>
              <li>
                You will join our team of developers and be given a buddy who
                will support you and guide you through the learning curve.
              </li>
              <li>
                You will be assigned a project, on which you will contribute
                from design to implementation.
              </li>
              <li>
                You will be interacting with clients and be involved in the
                deployment on the ground.
              </li>
            </ul>
            <ul>
              <span>What we need from you</span>
              <li>
                An understanding of Android development, Java, HTML5 and
                Javascript.
              </li>
              <li>A good mathematics background.</li>
              <li>An interest for challenges and a love of the job.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default GSDVacancyCard;
