import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import BookingForm from "../config/BookingForm";
import TrustedBy from "../components/TrustedBy";
import ContactForm from "../config/ContactForm";
import FooterCertificates from "../components/FooterCertificates";

function BookDemo() {
  const demoPageData = [
    {
      fact: "Book a demonstration",
    },
    {
      fact: "Tell us your requirements",
    },
    {
      fact: "See how we can customise our solutions for you",
    },
  ];

  return (
    <>
      <Nav />
      <div className="bookdemo-container">
        <div className="bookdemo-wrapper">
          <div data-aos="fade-right" className="bookdemo-text">
            <h1 className="bookdemo-header">Talk to our sales team</h1>
            <span className="bookdemo-subheading">
              Find out about our products and services
            </span>
            {demoPageData.map((fact, index) => (
              <p key={index} className="bookdemo-fact">
                <i>
                  <IconCircleCheckFilled size={24} />
                </i>
                {fact.fact}
              </p>
            ))}
          </div>
          <div className="book-demo-form-container">
            <BookingForm />
          </div>
        </div>
      </div>
      {/* <TrustedBy /> */}
      <div className="right-to-left-triangle-white"></div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default BookDemo;
