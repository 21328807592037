import React from "react";
import { useLanguage } from "../config/LanguageContext";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Front from "../assets/heros/Front 1.svg";
import { IoMdCalendar } from "react-icons/io";
import IndustryFeatures from "../components/IndustryFeatures";
import IndustryDiscover from "../components/IndustryDiscover";
import FooterCertificates from "../components/FooterCertificates";
import TrustedBy from "../components/TrustedBy";

function Industries() {
  const { currentLanguage, translations } = useLanguage();

  return (
    <>
      <Nav />
      <div className="landing-container">
        <div className="landing-section">
          <div className="landing-wrapper">
            <div data-aos="fade-right" className="landing-content">
              <h1 className="landing-header">
                {translations.industry[currentLanguage].header}
              </h1>
              <p className="white">
                {translations.industry[currentLanguage].paragraph}
              </p>
              <button className="cta-button-large">
                {translations.industry[currentLanguage].ctaButton}
              </button>
            </div>
          </div>
        </div>
        <div className="landing-image">
          <img src={Front} />
        </div>
      </div>
      <TrustedBy />
      <IndustryFeatures />
      <IndustryDiscover />
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Industries;
