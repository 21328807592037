import ISO from "../assets/resources/iso27001.pdf";
import Cyber from "../assets/resources/cyber-essentials.pdf";

export const resourcesTranslations = {
  en: {
    title: "Resources",
    subTitle:
      "Find legal information and resources for our products and services",
    cards: {
      general: {
        header: "General",
        content: [
          { title: "Privacy Statement & Terms", path: "/privacypolicy" },
          { title: "Cookie Policy", path: "/cookiepolicy" },
        ],
      },
      security: {
        header: "Security",
        content: [
          { title: "Cyber Essentials Plus Certificate", path: {Cyber} },
          { title: "ISO-27001 Certificate", path: {ISO} },
        ],
      },
      environment: {
        header: "Environment, Social and Governance",
        content: [
          // { title: "Carbon Reduction Plan 2022 - 2023", path: "/carbon-reduction-22-23" },
          { title: "Carbon Reduction Plan 2023 - 2024", path: "/carbon-reduction-23-24" },
        ],
      },
    },
  },
  fr: {
    title: "Resources",
    subTitle:
      "Find legal information and resources for our products and services",
    cards: {
      general: {
        header: "General",
        content: [
          { title: "Privacy Statement & Terms", path: "/privacypolicy" },
          { title: "Cookie Policy", path: "/cookie-policy" },
        ],
      },
      // security: {
      //   header: "Security",
      //   content: [
      //     { title: "Cyber Essentials Plus Certificate", path: "/assets/resources/cyber-essentials.pdf" },
      //     { title: "ISO-27001 Certificate", path: "/assets/resources/iso27001.pdf" },
      //   ],
      // },
      environment: {
        header: "Environment, Social and Governance",
        content: [
          { title: "Carbon Reduction Plan 2022 - 2023", path: "/carbon-reduction-22-23" },
          { title: "Carbon Reduction Plan 2023 - 2024", path: "/carbon-reduction-23-24" },
        ],
      },
    },
  },
};