import {
  IconUserShield,
  IconBoxMultiple,
  IconListDetails,
  IconMap,
  IconGitCompare,
  IconRefresh,
  IconClock,
  IconMail,
  IconCloudNetwork,
  IconFile,
  IconUpload,
  IconDatabase,
  IconCheck,
  IconSortAscending,
  IconEdit,
  IconShare,
  IconTrash,
  IconReport,
  IconLock
} from "@tabler/icons-react";

import D from "../assets/test/D.mp4"
import C from "../assets/test/C.jpg"

export const productFeaturesTranslations = {
  en: {
    dytask: {
      intuative: {
        icon: IconUserShield,
        text: "Intuitive mobile user interface designed for and with police officers",
      },
      power: {
        icon: IconBoxMultiple,
        text: "Thematic situation awareness",
      },
      easy: {
        icon: IconListDetails,
        text: "Objectives defined in plain English, to support police officers on patrol",
        image: C
      },
      advanced: {
        icon: IconMap,
        text: "Map-based with built-in hotspot policing",
        image: D
      },
      intergration: {
        icon: IconGitCompare,
        text: "Integrates with Command & Control, Crime Intelligence and HR systems",
      },
    },
    tram: {
      global: {
        icon: IconUserShield,
        text: "Manage firearms and their users",
      },
      power: {
        icon: IconRefresh,
        text: "Full asset life-cycle management",
      },
      easy: {
        icon: IconClock,
        text: "Intuitive and easy to use",
      },
      alert: {
        icon: IconMail,
        text: "Notifications and alerts",
      },
      cloud: {
        icon: IconCloudNetwork,
        text: "Cloud-based with role-based security and single-sign-on",
      },
      streamlined: {
        icon: IconFile,
        text: "Streamlined processes, no duplication and full authorisation process",
      },
      intergration: {
        icon: IconGitCompare,
        text: "Integrates with organisational back-office eg. HR, Finance systems, etc.",
      },
    },
    dams: {
      upload: {
        icon: IconUpload,
        text: "Content upload, individual or in bulk, through internal channels or crowdsourcing",
      },
      auto: {
        icon: IconDatabase,
        text: "Automatic ingestion of metadata",
      },
      valid: {
        icon: IconCheck,
        text: "Management and validation of metadata",
      },
      indexing: {
        icon: IconSortAscending,
        text: "Automatic indexing and categorisation of assets",
      },
      edit: {
        icon: IconEdit,
        text: "Editing and analysis of image, video, and audio",
      },
      map: {
        icon: IconMap,
        text: "Visualise assets through maps, timelines, and networks",
      },
      collect: {
        icon: IconShare,
        text: "Asset and collection sharing",
      },
      retention: {
        icon: IconTrash,
        text: "Retention and deletion schedules (MoPI compliant)",
      },
      full: {
        icon: IconReport,
        text: "Full suite of management reporting",
      },
      audit: {
        icon: IconLock,
        text: "Audit and security layers for peace of mind",
      },
    },
  },
  fr: {
    dytask: {
      intuative: {
        icon: IconUserShield,
        text: "Facile et intuitif à utiliser, conçu avec et pour les officiers de police",
      },
      power: {
        icon: IconBoxMultiple,
        text: "Sensibilisation à des situations thématiques puissantes",
      },
      easy: {
        icon: IconListDetails,
        text: "Objectifs définis dans un langage simple, facilement compréhensible et assimilé par les agents",
      },
      advanced: {
        icon: IconMap,
        text: "Solution cartographique avancée avec technologie de localisation en intérieur, localisation des ressources et des incidents, attribution des tâches, briefing et hotspots disponibles sur les ordinateurs de bureau et les appareils mobiles.",
      },
      intergration: {
        icon: IconGitCompare,
        text: "Intégration bidirectionnelle avec les systèmes existants des forces de police tels que les systèmes de renseignement sur la criminalité, le commandement et le contrôle, le SIG d'entreprise, les systèmes de ressources humaines, etc.",
      },
    },
    tram: {
      global: {
        icon: IconUserShield,
        text: "Répondre aux exigences globales de la gestion des armes à feu et de leurs utilisateurs",
      },
      power: {
        icon: IconRefresh,
        text: "Outils puissants et complets de gestion des actifs pour un suivi complet du cycle de vie",
      },
      easy: {
        icon: IconClock,
        text: "Facile à utiliser et intuitif, il minimise la formation nécessaire.",
      },
      alert: {
        icon: IconMail,
        text: "Les alertes et les communications par courrier électronique accélèrent les activités et les processus clés",
      },
      cloud: {
        icon: IconCloudNetwork,
        text: "Accès sécurisé basé sur le cloud et basé sur les rôles à travers le réseau de l'organisation à partir d'ordinateurs de bureau et d'appareils mobiles, mettant la solution à la disposition des utilisateurs autorisés où qu'ils se trouvent.",
      },
      streamlined: {
        icon: IconFile,
        text: "Le processus rationalisé élimine les retards et les doubles emplois dans le traitement des formulaires ou des données hors ligne ; les dossiers sont instantanément mis à jour ; les autorisations sont limitées dans le temps et précises.",
      },
      intergration: {
        icon: IconGitCompare,
        text: "Intégration complète avec les données de l'organisation telles que les systèmes de ressources humaines, la formation, la comptabilité et la gestion des stocks.",
      },
    },
    dams: {
      upload: {
        icon: IconUpload,
        text: "Téléchargement de fichiers - téléchargement individuel ou en masse de fichiers, avec possibilité de téléchargement interne et public",
      },
      auto: {
        icon: IconDatabase,
        text: "L'ingestion automatique de métadonnées",
      },
      valid: {
        icon: IconCheck,
        text: "Validation et gestion des métadonnées",
      },
      indexing: {
        icon: IconSortAscending,
        text: "Indexation et catégorisation automatiques des actifs",
      },
      edit: {
        icon: IconEdit,
        text: "Édition et analyse d'images, de vidéos, d'audio, etc. avec contrôle total des versions",
      },
      map: {
        icon: IconMap,
        text: "Capacité de localisation des actifs à partir de cartes, de chronologies et de réseaux",
      },
      collect: {
        icon: IconShare,
        text: "Partage des actifs et des collections",
      },
      retention: {
        icon: IconTrash,
        text: "Planification de la conservation et de la suppression des actifs (conforme aux MoPI)",
      },
      full: {
        icon: IconReport,
        text: "Suite complète de rapports de gestion",
      },
      audit: {
        icon: IconLock,
        text: "Audit et couches de sécurité pour la tranquillité d'esprit",
      },
    },
  },
};
