import React from "react";
import Nav from "../components/Nav";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import CareersForm from "../config/CareersForm";
import { useLanguage } from "../config/LanguageContext";
import Vacancies from "../components/Vacancies";

function Careers() {
  const { currentLanguage, translations } = useLanguage();

  return (
    <>
      <Nav />
      <div className="careers-container">
        <div className="about-us-header">
          <div className="careers-header-container">
            <h1>{translations.careers[currentLanguage].title}</h1>
          </div>
        </div>
        <div className="upside-down-left-to-right-triangle-secondary"></div>
        <div className="careers-wrapper">
          <div>
            <h2>Why join?</h2>
            <p>
              We are a small dynamic and friendly business investing a lot in
              research & development. We are passionate about producing
              innovative solutions for policing, which is a tremendous subject.
              Our colleagues enjoy the freedom to spend time investigating new
              technologies (some call it playing with gadgets or new tech); and
              they are all encouraged to express themselves openly, which in a
              large organisation would be difficult.
              <br></br>
              <br></br>
              As part of your work with us, you will experience various
              platforms (cloud, mobile, embedded), developing in a variety of
              languages such as C, C++, Objective-C, C#, Java, and HTML5 as well
              as more exotic languages. The point is that it will never be
              boring.
              <br></br>
              <br></br>
              At Innaxys, we like our developers to work in close collaboration
              with clients, presenting and discussing their own work with police
              officers and users without the need for the ‘middle man with a
              tie’.
              <br></br>
              <br></br>
              What you will get working with us is an exciting yet challenging
              job but certainly not a 9 to 5 one; you will be an integral part
              of a supportive, engaging and inclusive team.
            </p>
          </div>
          <div>
            <h2>About you</h2>
            <p>
              We are a computer science company and we are looking for people
              with a good scientific background i.e. mathematics in particular,
              who can apply their talents in a practical way to develop
              solutions that will make the difference.
              <br></br>
              <br></br>
              Whether you are a shy genius or communicative geek, we want to
              hear from you. You must be passionate about what you do and not
              hesitate to stand by your work and your ideas. Be assured that you
              will have the interest of everyone here; not least our Chief
              Executive who started his career as an engineer and a software
              developer, and even now continues to get involved in this side of
              the business., we still correct his bugs today and he is always
              keen to discuss techniques and new ideas with the team at any and
              every opportunity.
            </p>
          </div>
          <div>
            <h2>Recruitment process</h2>
            <p>
              Firstly, don’t expect any psychological tests or personality
              assessment because that’s not the way we do things. Here we like
              originality and we prefer to ‘discover’ you during the process.
              <br></br>
              <br></br>
              We will start with a classic but relaxed face-to-face interview,
              after which we may ask you to develop a small project based on
              requirements similar to those we are often set by our clients
              themselves. You will take this project home and have enough time
              to develop it. This is not a race so take your time; what we are
              interested in is the quality of your work, the attention to detail
              and how you are able to present and defend it. You will even be
              able to come to see us, share a coffee, ask questions…
              <br></br>
              <br></br>
              Eventually you will present your project and we will discuss it
              together.
            </p>
          </div>
          {/* <h2 className="careers-subheading ">
            {translations.careers[currentLanguage].why[0].header}
          </h2>
          {translations.careers[currentLanguage].why[0].text.map(
            (paragraph, index) => (
              <p key={index} className="">
                {paragraph}
              </p>
            )
          )}
          <h2 className="careers-subheading ">
            {translations.careers[currentLanguage].about[0].header}
          </h2>
          {translations.careers[currentLanguage].about[0].text.map(
            (paragraph, index) => (
              <p key={index} className="">
                {paragraph}
              </p>
            )
          )}
          <h2 className="careers-subheading ">
            {translations.careers[currentLanguage].recruitment[0].header}
          </h2>
          {translations.careers[currentLanguage].recruitment[0].text.map(
            (paragraph, index) => (
              <p key={index} className="">
                {paragraph}
              </p>
            )
          )} */}
        </div>
      </div>
      <Vacancies />
      <div className="left-to-right-triangle"></div>
      <div className="interest-section-container">
        <div className="interest-section-wrapper">
          <h2>Interested in joining the team?</h2>
          <p>
            Contact us via the form below to learn about careers at Innaxys, and
            share a bit about yourself, experience and interests.
          </p>
        </div>
        <div className="book-demo-form-container">
          <CareersForm />
        </div>
      </div>
      <div className="upside-down-left-to-right-triangle"></div>
      {/* <div className="triangle-divider"></div> */}
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Careers;
