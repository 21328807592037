import React, { useState } from "react";
import { IconCaretDownFilled } from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";

function NavDropdownModal({ handleNavItemClick, handleBookDemoClick }) {
  const [showProductsOptions, setShowProductsOptions] = useState(false);
  const [showIndustriesOptions, setShowIndustriesOptions] = useState(false);
  const [showCompanyOptions, setShowCompanyOptions] = useState(false);

  const location = useLocation();

  const toggleOptions = (option) => {
    switch (option) {
      case "products":
        setShowProductsOptions(!showProductsOptions);
        setShowIndustriesOptions(false);
        setShowCompanyOptions(false);
        break;
      case "industries":
        setShowProductsOptions(false);
        setShowIndustriesOptions(!showIndustriesOptions);
        setShowCompanyOptions(false);
        break;
      case "company":
        setShowProductsOptions(false);
        setShowIndustriesOptions(false);
        setShowCompanyOptions(!showCompanyOptions);
        break;
      default:
        break;
    }
  };

  const handleEnterPress = (path) => {
    handleNavItemClick(path);
  };

  return (
    <div className="dropdown-nav-container">
      <div
        className="dropdown-nav-option-wrapper"
        onClick={() => toggleOptions("products")}
        // onFocus={() => toggleOptions("products")} // Toggle children when focused
        tabIndex={0} // Ensure focusability
      >
        <div className="nav-item-container">
          <span>Solutions</span>
        </div>
        <div className="caret-wrapper">
          <IconCaretDownFilled
            size={20}
            className={`caret ${showProductsOptions ? "active" : ""}`}
          />
        </div>
      </div>
      {showProductsOptions && (
        <div className="dropdown-nav-more-options">
          <Link
            to="/tram"
            className={`dropdown-item ${
              location.pathname === "/tram" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/tram");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            <span>TRAM</span>
            <p>Training Records & Asset Management Solution </p>
          </Link>
          <Link
            to="/deams"
            className={`dropdown-item ${
              location.pathname === "/deams" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/deams");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            <span>DEAMS</span>
            <p>Digital Evidence & Asset Management Solution</p>
          </Link>
          <Link
            to="/dytask"
            className={`dropdown-item ${
              location.pathname === "/dytask" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/dytask");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            <span>DyTASK</span>
            <p>
              GIS for Policing, with demand prediction and effective patrol and
              tasking management
            </p>
          </Link>
        </div>
      )}
      <div
        className="dropdown-nav-option-wrapper"
        onClick={() => toggleOptions("company")}
        // onFocus={() => toggleOptions("company")} // Toggle children when focused
        tabIndex={0} // Ensure focusability
      >
        <div className="nav-item-container">
          <span>Company</span>
        </div>
        <div className="caret-wrapper">
          <IconCaretDownFilled
            size={20}
            className={`caret ${showCompanyOptions ? "active" : ""}`}
          />
        </div>
      </div>
      {showCompanyOptions && (
        <div className="dropdown-nav-more-options">
          <Link
            to="/about"
            className={`dropdown-item ${
              location.pathname === "/about" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/about");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            About
          </Link>
          <Link
            to="/resources"
            className={`dropdown-item ${
              location.pathname === "/resources" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/resources");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            Resources
          </Link>
          <Link
            to="/careers"
            className={`dropdown-item ${
              location.pathname === "/careers" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/careers");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            Join
          </Link>
          <Link
            to="/contact"
            className={`dropdown-item ${
              location.pathname === "/contact" ? "active-dropdown-item" : ""
            }`}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress("/contact");
              }
            }}
            tabIndex={0} // Ensure focusability
          >
            Contact
          </Link>
        </div>
      )}
      <div className="dropdown-button-container">
        <button className="cta-button-dropdown" onClick={handleBookDemoClick}>
          Request a demo
        </button>
      </div>
    </div>
  );
}

export default NavDropdownModal;
