export const navTranslations = {
  en: {
    products: {
      navName: "Products",
      path: "/products" 
    },
    industries: { 
      navName:"Industries",
      path: "/industries"
    },
    company: {
      navName: "Company",
      path: "/company"
    }
  },
  fr: {
    products: {
      navName: "Produits",
      path: "/products" 
    },
    industries: { 
      navName:"Industries",
      path: "/industries"
    },
    company: {
      navName: "Entreprise",
      path: "/company"
    }
  }
}