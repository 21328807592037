export const tramTranslations = {
  en: {
    tramHeader: "TRAM - Training Records & Asset Management Solution ",
    tramHeaderButton: "Request a demo",
    tramContent: {
      header:
        "Managing firearm officers’ training, authorisations and armouries with a cloud-based and mobile solution",
      textHead: [
        "Our Training Records & Asset Management (TRAM) application is a complete cloud-based, mobile-enabled Solution to manage the administration of the firearms function of security organisations(police, border security, private security organisations, etc.). The Solution is expandable to include other types of training and asset management, such as for fire and rescue services, higher education institutes, and any organisation who have a requirement to track the lifecycle including servicing of high value equipment as well as related user competencies and training.",
      ],
    },
  },
  fr: {
    tramHeader: "Gestion des dossiers et des actifs de formation (TRAM)",
    tramHeaderButton: "Réserver une démo",
    tramContent: {
      header:
        "Managing firearm officers’ training, authorisations and armouries with a cloud-based and mobile solution",
      textHead: [
        "Notre application TRAM (Training Records & Asset Management) est une solution complète, basée sur le cloud et adaptée aux mobiles, qui permet de gérer l'administration de la fonction armes à feu des organisations de sécurité (police, sécurité des frontières, organisations de sécurité privée, etc.) La solution est extensible pour inclure d'autres types de formation et de gestion des actifs, tels que les services d'incendie et de secours, les instituts d'enseignement supérieur, et toute organisation qui a besoin de suivre le cycle de vie, y compris l'entretien des équipements de grande valeur, ainsi que les compétences et la formation de l'utilisateur.",
      ],
    },
  },
};
