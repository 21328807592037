import React from 'react'

function IndustryFeature({ data }) {
  return (
    <div className='industry-feature-container'>
      <i className='industry-feature-icon'>
        {data.icon}
      </i>
      <h3 className='industry-features-header'>{data.header}</h3>
      <p>{data.text}</p>
    </div>
  )
}

export default IndustryFeature