import React from "react";
import Nav from "../components/Nav";
import Map from "../assets/heros/DyTASK 1.svg";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import { useLanguage } from "../config/LanguageContext";
import DytaskFeatures from "../components/dytaskFeatures";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../components/headerSections";

function Dytask() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  return (
    <>
      <Nav />
      <HeaderSection
        header="DyTASK - Optimise you workforce and enhance public safety"
        buttonText="Request a demo"
        image={Map}
        onClick={handleBookDemoClick}
      />
      <span className="wraps">
        <div className="right-to-left-triangle-white"></div>
      </span>
      <div className="dytask-content-container">
        <div className="dytask-content-wrapper">
          <h2 className="secondary">
            DyTASK provides a solution for evidence-based policing and demand
            management through optimised patrols and hotspot policing
            {/* {translations.dytask[currentLanguage].dytaskContent.header} */}
          </h2>
          <p>
            Using AI and crime science, DyTASK redefines evidence-based policing
            with smart tasking and briefing.
          </p>
          <p>
            All police forces face the daily challenge of deploying a limited
            number of officers on patrol with the hope of making a difference to
            their communities, improving public confidence, and reducing crime.
          </p>
          <p>
            DyTASK provides a solution to this problem, using evidence-based
            methodologies and intelligence on crime and community to produce
            optimised patrols and policing hotspots whilst monitoring delivery
            and impact.
          </p>
          <p>
            DyTASK is a cloud-based application, built atop of the Innaxys
            corporate analytical GIS with a browser-based client for supervisors
            and a mobile application for officers on the beat and response
            units.{" "}
          </p>
          <p>Key benefits include:</p>
          <ul className="product-feature-list">
            <li>Supporting crime reduction and increasing policing visibility while delivering cashable savings</li>
            <li>Enabling hotspot policing, getting officers in the right place, at the right time, for the right duration</li>
            <li>Real-time Over The Air tasking and briefing</li>
            <li>Performance management, analytics and lessons learnt</li>
          </ul>
          {/* {translations.dytask[currentLanguage].dytaskContent.textHead.map(
            (textHead, index) => (
              <p className="secondary" key={index}>
                {textHead}
              </p>
            )
          )}
          <h4 className="secondary">
            {translations.dytask[currentLanguage].dytaskContent.imagine}
          </h4>
          <p>{translations.dytask[currentLanguage].dytaskContent.text[0]}</p>
          <p>{translations.dytask[currentLanguage].dytaskContent.text[1]}</p>
          <p>{translations.dytask[currentLanguage].dytaskContent.text[2]}</p>
          <p>{translations.dytask[currentLanguage].dytaskContent.text[3]}</p> */}
        </div>
      </div>
      <div className="left-to-right-triangle-products"></div>
      <div className="dytask-features-container">
        <DytaskFeatures />
      </div>
      <div className="upside-down-left-to-right-triangle"></div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Dytask;
