import React, { useState, useEffect } from "react";
import NavLogo from "../assets/InnaxysLogo.svg";
import {
  TbWorld,
  TbMenu2,
  TbCaretDownFilled,
  TbArrowNarrowRight,
  TbX,
} from "react-icons/tb";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageModal from "../components/LanguageModal.jsx";
import { useLanguage } from "../config/LanguageContext.jsx";
import NavDropdownModal from "./navDropdownModal.jsx";
import $ from "jquery";

const Nav = () => {
  const { currentLanguage, handleLanguageChange } = useLanguage();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isActivePath = (paths) => {
    for (const path of paths) {
      if (location.pathname === path) return true;
    }
    return false;
  };

  const toggleLanguageModal = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  useEffect(() => {
    const handleClick = () => {
      const navIcon = document.getElementById("nav-icon3");
      navIcon.classList.toggle("open");
    };
  
    const navIcon = document.getElementById("nav-icon3");
    navIcon.addEventListener("click", handleClick);
  
    return () => {
      navIcon.removeEventListener("click", handleClick);
    };
  }, []);

  const navItems = [
    {
      name: {
        en: "Solutions",
        fr: "Solutions",
      },
      dropdownItems: [
        {
          header: "TRAM",
          label: "Training Records & Asset Management Solution",
          path: "/tram",
        },
        {
          header: "DEAMS",
          label: "Digital Evidence & Asset Management Solution",
          path: "/deams",
        },
        {
          header: "DyTASK",
          label:
            "GIS for Policing, with demand prediction and effective patrol and tasking management",
          path: "/dytask",
        },
      ],
      solution: {
        en: "Solution Overview",
        fr: "",
        icon: { TbArrowNarrowRight },
      },
    },
    {
      name: {
        en: "Company",
        fr: "Compagnie",
      },
      dropdownItems: [
        { label: "About", path: "/about" },
        { label: "Resources", path: "/resources" },
        { label: "Join", path: "/careers" },
        { label: "Contact", path: "/contact" },
      ],
    },
  ];

  const handleNavItemClick = (path) => {
    navigate(path);
  };

  const handleDropdownItemClick = (path) => {
    navigate(path);
  };

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  const toggleDropdown = () => {
    setShowMenu(false);
    setShowDropdown(!showDropdown);
  };

  const handleMenuKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowDropdown(true);
    }
  };

  const handleKeyDown = (event, path) => {
    if (event.key === "Enter") {
      navigate(path);
    }
  };

  return (
    <section className="navbar-wrapper">
      <div className="navbar">
        <figure className="innaxys-logo">
          <a href="/">
            <img src={NavLogo} alt="Nav Logo" />
          </a>
        </figure>
        <div className="nav-options-container">
          <ul className="nav-options">
            {navItems.map((item, index) => (
              <li
                className={`nav-option ${
                  isActivePath([
                    item.path,
                    ...item.dropdownItems.map((item) => item.path),
                  ])
                    ? "active-nav"
                    : ""
                }`}
                key={index}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                onFocus={() => setHoveredItem(index)}
              >
                <div
                  className="nav-item-container"
                  onClick={() => handleNavItemClick(item.path)}
                  onKeyDown={(event) => handleKeyDown(event, item.path)}
                  tabIndex="0"
                >
                  {item.name[currentLanguage]}
                  <span className="nav-caret">
                    <TbCaretDownFilled className="caret" size={20} />
                  </span>
                </div>
                {hoveredItem === index && (
                  <div className="desktop-nav-dropdown-container">
                    <ul className="desktop-nav-dropdown">
                      {item.dropdownItems.map((dropdownItem, i) => (
                        <li
                          className={`desktop-dropdown-nav-option ${
                            location.pathname === dropdownItem.path
                              ? "active-dropdown-item"
                              : ""
                          }`}
                          key={i}
                          onClick={() =>
                            handleDropdownItemClick(dropdownItem.path)
                          }
                          onKeyDown={(event) =>
                            handleKeyDown(event, dropdownItem.path)
                          }
                          tabIndex="0"
                        >
                          {index === 1 ? (
                            <p>{dropdownItem.label}</p>
                          ) : (
                            <>
                              <span>{dropdownItem.header}</span>
                              <p>{dropdownItem.label}</p>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="nav-right-side">
          <button
            onClick={handleBookDemoClick}
            className={`cta-button nav-button ${showMenu ? "hidden" : ""}`}
          >
            {currentLanguage === "en" ? "Request a demo" : "Réserver une démo"}
          </button>
          <i
            className="hamburger-menu nav-icon"
            onClick={toggleDropdown}
            onKeyDown={handleMenuKeyDown}
            tabIndex="0"
          >
            <div id="nav-icon3">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </i>
        </div>
      </div>
      <div
        className={`overlay ${showDropdown ? "visible" : ""}`}
        onClick={() => setShowDropdown(false)}
      ></div>
      {showDropdown && (
        <NavDropdownModal
          onClose={() => setShowDropdown(false)}
          handleNavItemClick={handleNavItemClick}
          handleBookDemoClick={handleBookDemoClick}
        />
      )}
    </section>
  );
};

export default Nav;
