import React, { useState } from "react";

const BookingForm = () => {
  const [status, setStatus] = useState("Send us your message");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const formData = new FormData(e.target);
    const details = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      company: formData.get("company"),
      message: formData.get("message"),
    };

    try {
      const response = await fetch("http://localhost:5000/bookdemo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Send us your message");
      const result = await response.json();
      if (response.ok) {
        alert(result.status);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to send message. Please try again later.");
    }
  };

  return (
    <form action="./mailer.php" method="POST">
      <div className="form-input-container">
        <div className="singular-input-container">
          <label htmlFor="firstName">First Name</label>
          <input type="text" id="firstName" name="firstName" required />
        </div>
        <div className="singular-input-container">
          <label htmlFor="lastName">Last Name</label>
          <input type="text" id="lastName" name="lastName" required />
        </div>
      </div>
      <div className="form-input-container">
        <div className="singular-input-container">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="singular-input-container">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            pattern="[0-9]{10}"
            required
          />
        </div>
      </div>
      <div className="form-input-container-full">
        <label htmlFor="company">Company</label>
        <input type="text" id="company" name="company" required />
      </div>
      <div className="form-input-container-full">
        <label htmlFor="message">How can we help you?</label>
        <textarea id="message" name="message" required></textarea>
      </div>
      <button className="cta-button-large-careers" type="submit">
        {status}
      </button>
      <input type="hidden" name="subject" />
    </form>
  );
};

export default BookingForm;
