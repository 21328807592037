import React from "react";
import { useLanguage } from "../config/LanguageContext";
import test from "../assets/test/abc.jpg"

function DytaskFeatures() {
  const { currentLanguage, translations } = useLanguage();
  const productFeatures = translations.productFeatures[currentLanguage]?.dytask;

  if (!productFeatures) {
    return null;
  }

  return (
    <>
      <div className="features-container">
        {Object.keys(productFeatures).map((featureKey) => {
          const feature = productFeatures[featureKey];
          const IconComponent = feature.icon;
          return (
              <div key={featureKey} className="product-feature-wrapper">
                <div className="feature-icon-container">
                  {IconComponent && <IconComponent size={40} color="#091e42" stroke={1.5} />}
                </div>
                <p>{feature.text}</p>
              </div>
          );
        })}
      </div>
      {/* <div className="features-container-new">
        {Object.keys(productFeatures).map((featureKey) => {
          const feature = productFeatures[featureKey];
          const IconComponent = feature.icon;
          return (
            <div key={featureKey} className="product-feature-wrapper-new">
              <div className="feature-icon-container-new">
                <img className="test-img" src={feature.image} alt="" />
              </div>
              <div className="feature-context-new">
                <p>{feature.text}</p>
               
              </div>
              {IconComponent && <IconComponent className="feature-icon" size={200} />}
            </div>
          );
        })}
      </div> */}
    </>
  );
}

export default DytaskFeatures;