import React from "react";
import Nav from "../components/Nav";
import Camera from "../assets/heros/DEAMS 1.svg";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import { useLanguage } from "../config/LanguageContext";
import DamsFeatures from "../components/damsFeatures";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../components/headerSections";

function Dams() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  }

  return (
    <>
      <Nav />
      <HeaderSection
        header="DEAMS - Digital Evidence & Asset Management Solution"
        buttonText="Request a demo"
        image={Camera}
        onClick={handleBookDemoClick}
      />
      <span className="wraps">
        <div className="right-to-left-triangle-white"></div>
      </span>
      <div className="dytask-content-container">
        <div className="dytask-content-wrapper">
          <h2 className="secondary">
            {translations.dams[currentLanguage].damsSubheading}
          </h2>
          <p>Innaxys DEAMS is a comprehensive web-based Digital Evidence & Asset Management Solution providing an end-to-end management of digital assets, such as multimedia content, documents, files or forensic evidence, with configurable workflows. It complies with evidential standards and is used by security agencies and other government departments in the UK and Europe. </p>
          <p>DEAMS incorporates a suite of advanced editing and analytical tools, as well as the ability to control and manage all available metadata and retention/deletion requirements, with functionality to securely share images and files with other users, teams or organisations.</p>
          <p>Together, DEAMS modules enable:</p>
          <ul className="product-feature-list">
            <li>Content upload, individually or in bulk, through internal, partner or public upload channels</li>
            <li>Automatic ingestion and management of metadata, with validation process</li>
            <li>Automatic indexing and categorisation of assets</li>
            <li>Editing	and analysis of images, video, audio contents with full version control</li>
            <li>Geolocation, timeline and network-based asset management</li>
            <li>Asset and collection sharing</li>
            <li>Asset retention and deletion scheduling (MoPI compliant)</li>
            <li>Full suite of management reporting</li>
            <li>Evidential auditing</li>
          </ul>
          <p>DEAMS can be hosted on a cloud environment private to your organisation or commercial with full managed service</p>
          {/* {translations.dams[currentLanguage].text.map(
            (text, index) => (
              <p className="secondary" key={index}>
                {text}
              </p>
            )
          )} */}
        </div>
      </div>
      <div className="left-to-right-triangle-products"></div>
      <div className="dytask-features-container">
        <DamsFeatures />
      </div>
      <div className="upside-down-left-to-right-triangle"></div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Dams;
