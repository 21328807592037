import React from "react";
import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FooterCertificates from "../components/FooterCertificates";
import { useLanguage } from "../config/LanguageContext";
import ISO from "../assets/resources/iso27001.pdf";
import Cyber from "../assets/resources/cyber-essentials.pdf";

function Resources() {
  const { currentLanguage, translations } = useLanguage();

  return (
    <>
      <Nav />
      <div className="resources-container">
        <div className="resources-header-container">
          <div className="resources-wrapper">
            <h1 className="gray">
              {translations.resources[currentLanguage].title}
            </h1>
          </div>
        </div>
        <div className="resources-hub-container">
          <div className="resources-hub-wrapper">
            {Object.keys(translations.resources[currentLanguage].cards).map(
              (key, index) => (
                <div key={index} className="option-container">
                  <h2 className="resource-card-header">
                    {translations.resources[currentLanguage].cards[key].header}
                  </h2>
                  <ul className="resource-card-list">
                    {translations.resources[currentLanguage].cards[
                      key
                    ].content.map((item, itemIndex) => (
                      <li className="resources-list-item" key={itemIndex}>
                        {key === "security" ? (
                          <a
                            href={
                              item.title === "Cyber Essentials Plus Certificate"
                                ? Cyber
                                : ISO
                            }
                            className="resources-path"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <Link
                            to={item.path} // Assuming other links point to routes
                            className="resources-path"
                          >
                            {item.title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Resources;
