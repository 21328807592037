import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Officer from "../assets/heros/Officer.jpg";
import IndustryFeatures from "../components/IndustryFeatures";

function Products() {
  return (
    <>
      <Nav />
      <div className="landing-container">
        <div className="landing-section">
          <div className="landing-wrapper">
            <div data-aos="fade-right" className="landing-content">
              <h1 className="landing-header">Digital Asset Management System</h1>
            <p className="white">
              Baseline emissions are a record of the greenhouse gases that have
              been produced in the past and were produced prior to the
              introduction of any strategies to reduce emissions. Baseline
              emissions are the reference point against which emissions
              reduction can be measured.
            </p>
            <button className="cta-button-large-white">Book a demo</button>
          </div>
          </div>
        </div>
        <div className="landing-image">
          <img src={Officer} />
        </div>
      </div>
      <div className="right-to-left-triangle-white"></div>
      <div className="product-features-container">
        <div className="product-feature">
          {/* <div className="product-features-text">
            <h2>Route Planning</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu
              bibendum velit. Aenean rhoncus eleifend enim, id luctus massa
              lacinia non.{" "}
            </p>
          </div>
          <figure className="product-features-image">
            <img src={Officer} />
          </figure>
        </div>
        <div className="product-feature">
          <div className="product-features-text">
            <h2>Route Planning</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu
              bibendum velit. Aenean rhoncus eleifend enim, id luctus massa
              lacinia non.{" "}
            </p>
          </div>
          <figure className="product-features-image">
            <img src={Officer} />
          </figure>
        </div>
        <div className="product-feature">
          <div className="product-features-text">
            <h2>Route Planning</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu
              bibendum velit. Aenean rhoncus eleifend enim, id luctus massa
              lacinia non.{" "}
            </p>
          </div>
          <figure className="product-features-image">
            <img src={Officer} />
          </figure> */}
        </div>
      </div>
      <div className="upsidedown-right-to-left-triangle white-bg-bottom"></div>
      <IndustryFeatures />
      <Footer />
    </>
  );
}

export default Products;
