import React, { useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FooterCertificates from "../components/FooterCertificates";
import { useLanguage } from "../config/LanguageContext";
import JCreal from "../assets/aboutUs/JC.svg";
import JC from "../assets/aboutUs/image Test 1.svg";
import Mark from "../assets/aboutUs/Portrait 1.svg";
import Naomi from "../assets/aboutUs/image Test2 1.svg";
import Lineup from "../assets/teamCardImages/Lineup 1.svg";
import AboutUsTeamCard from "../components/AboutUsTeamCard";
import { IconBrandLinkedin } from "@tabler/icons-react";

function AboutUs() {
  const { currentLanguage, translations } = useLanguage();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Nav />
      <div className="about-us-header">
        <div className="text-container">
          <h1 className="gray">About Us</h1>
        </div>
      </div>
      <div className="upside-down-left-to-right-triangle-secondary"></div>
      <div className="about-us-text-container">
        <div className="about-us-text">
          {translations.aboutUs[currentLanguage].paragraphs.map(
            (paragraphs, index) => (
              <p className="secondary" key={index}>
                {paragraphs}
              </p>
            )
          )}
        </div>
      </div>
      <div>
        <div className="whole-team-container">
          <img src={Lineup} alt="The Whole Team" />
        </div>
        <div className="about-us-tabs-container">
          <div className="about-us-tabs">
            {translations.aboutUs[currentLanguage].headers.map(
              (headers, index) => (
                <div
                tabIndex={0}
                  className={`about-us-tab ${
                    index === activeTab ? "about-us-active-tab" : ""
                  }`}
                  onClick={() => setActiveTab(index)}
                  key={index}
                  onFocus={() => setActiveTab(index)}
                >
                  <h2 className="white">{headers}</h2>
                </div>
              )
            )}
          </div>
          <div className="about-us-tab-content-container">
            {translations.aboutUs[currentLanguage].headers.map(
              (header, index) => (
                <div
                  className="about-us-tab-content"
                  key={index}
                  style={{
                    display: index === activeTab ? "block" : "none",
                  }}
                >
                  {index === 0 && (
                    <>
                      <p className="about-us-tab-element gray">
                        {translations.aboutUs[currentLanguage].consultancy.text}
                      </p>
                      <ul className="about-us-list">
                        {translations.aboutUs[
                          currentLanguage
                        ].consultancy.list.map((list, listIndex) => (
                          <li className="gray" key={listIndex}>
                            {list}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      {translations.aboutUs[currentLanguage].solutions.text.map(
                        (text, textIndex) => (
                          <p
                            className="about-us-tab-element gray"
                            key={textIndex}
                          >
                            {text}
                          </p>
                        )
                      )}
                    </>
                  )}
                  {index === 2 && (
                    <>
                      {translations.aboutUs[currentLanguage].rnd.text.map(
                        (text, textIndex) => (
                          <p
                            className="about-us-tab-element gray"
                            key={textIndex}
                          >
                            {text}
                          </p>
                        )
                      )}
                    </>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {/* <div className="upside-down-right-to-left-triangle-white-about"></div>
      <div className="about-us-staff-container">
        <div className="about-us-staff-text-container">
          <div className="ceo-heading-container">
            <div className="ceo-titles">
              <h2>Jean-Claude Lafontaine</h2>
            </div>
            <h3 className="secondary">CEO</h3>
          </div>
          {translations.aboutUs[currentLanguage].descriptionOfJeanClaude.map(
            (descriptionOfJeanClaude, index) => (
              <p key={index}>{descriptionOfJeanClaude}</p>
            )
          )}
        </div>
        <div className="ceo-image-container">
          <img src={JCreal} alt="CEO" className="about-us-staff-image" />
        </div>
      </div>
      <div className="right-to-left-triangle-white"></div> */}
      <div className="team-lead-card-container">
        <h2>Management Team</h2>
        <div className="team-lead-card-wrapper">
          <div className="team-lead-card">
            <div className="team-lead-details">
              <h3 className="secondary">Jean-Claude Lafontaine</h3>
              <p>Chief Executive Officer</p>
            </div>
            <div className="team-lead-image-container">
              <img src={JC} alt="Mark Attenborough" />
            </div>
          </div>
          <div className="team-lead-card">
            <div className="team-lead-details">
              <h3 className="secondary">Mark Attenborough</h3>
              <p>Technical Director</p>
            </div>
            <div className="team-lead-image-container">
              <img src={Mark} alt="Mark Attenborough" />
            </div>
          </div>
          <div className="team-lead-card">
            <div className="team-lead-details">
              <h3 className="secondary">Naomi Porter</h3>
              <p>Commercial Lead</p>
            </div>
            <div className="team-lead-image-container">
              <img src={Naomi} alt="Mark Attenborough" />
            </div>
          </div>
        </div>
      </div>

      <div className="about-us-family-container">
        <div className="family-text-wrapper">
          <h2 className="white">
            {translations.aboutUs[currentLanguage].family.header}
          </h2>
          <p className="white">
            {translations.aboutUs[currentLanguage].family.text}
          </p>
        </div>
      </div>
      <div className="upside-down-right-to-left-triangle-secondary"></div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default AboutUs;
