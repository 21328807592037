import React from "react";
import CEP from "../assets/footerCompanies/CEPN.svg";
import MIB from "../assets/footerCompanies/MadeInBritain.svg";
import ISO from "../assets/footerCompanies/ISO.svg";
import CCS from "../assets/footerCompanies/CCS.svg";

function FooterCertificates() {
  const footerCertificateLogos = [
    {
      logo: CEP,
      label: "Cyber Essentials Plus",
    },
    {
      logo: ISO,
      label: "International Security Organisation",
    },
    {
      logo: MIB,
      label: "Made In Britain",
    },
    {
      logo: CCS,
      label: "Made In Britain",
    },
  ];

  return (
    <div className="footer-certificate-container">
      {footerCertificateLogos.map((logo, index) => (
        <img
          key={index}
          className="footer-certificate-logo"
          src={logo.logo}
          alt={logo.label}
        />
      ))}
    </div>
  );
}

export default FooterCertificates;
