import React, { useEffect, useRef } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Table from "../components/TableTemplate";
import { IconArrowLeft } from "@tabler/icons-react";
import { useLanguage } from "../config/LanguageContext";
import Signiture from "../assets/signiture.svg";
import { motion, useScroll } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FooterCertificates from "../components/FooterCertificates";
import CRP from "../assets/resources/CRP23-24.pdf"

function CarbonReduction() {
  const { currentLanguage, translations } = useLanguage();
  const { scrollYProgress } = useScroll();
  const navigate = useNavigate();
  const sectionRefs = useRef([]);

  useEffect(() => {
    const header = document.getElementById("myHeader");
    if (!header) return;

    const sticky = header.offsetTop;

    const handleScroll = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateToResources = () => {
    navigate("/resources");
  };

  const scrollToSection = (index) => {
    if (sectionRefs.current[index]) {
      const offset = -200; // Adjust this value as needed
      const sectionPosition = sectionRefs.current[index].offsetTop;
      window.scrollTo({
        top: sectionPosition + offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Nav />
      <div className="resources-container">
        <div className="resources-header-container">
          <div className="resources-wrapper">
            <h1 className="gray">
              {translations.carbonReduction[currentLanguage].header}
            </h1>
            <p className="privacy-policy-subheader">
              {translations.carbonReduction[currentLanguage].subHeader}
            </p>
            <button className="cta-button">
              <a href={CRP}>
              {translations.carbonReduction[currentLanguage].pdfButton}
              </a>
            </button>
          </div>
        </div>
        <div className="header" id="myHeader">
          <div className="back-container" onClick={navigateToResources}>
            <div className="back-wrapper">
              <span className="back-to-overview">
                <IconArrowLeft size={20} />{" "}
                {translations.carbonReduction[currentLanguage].return}
              </span>
            </div>
          </div>
          <div className="progress-bar-container">
            <motion.div
              className="progress-bar"
              style={{
                scaleX: scrollYProgress,
                backgroundColor: "var(--primary)",
                height: "10px",
              }}
            />
          </div>
        </div>
        <div className="resources-content-container">
          <div className="resources-contents-wrapper">
            <div className="resources-contents-container">
              <h2 className="secondary">
                {translations.carbonReduction[currentLanguage].contentHeader}
              </h2>
              <ul className="resources-contents-list">
                {translations.carbonReduction[currentLanguage].contents.map(
                  (contents, index) => (
                    <li
                      tabIndex={0}
                      key={index}
                      onClick={() => scrollToSection(index + 1)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          scrollToSection(index);
                        }
                      }}
                    >
                      {contents}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="resources-body-container">
              <h2
                className="secondary"
                ref={(el) => (sectionRefs.current[1] = el)}
                id="section1"
              >
                {
                  translations.carbonReduction[currentLanguage].commitment
                    .header
                }
              </h2>
              <p>
                {translations.carbonReduction[currentLanguage].commitment.text}
              </p>
              <h2
                className="secondary"
                ref={(el) => (sectionRefs.current[2] = el)}
                id="section2"
              >
                {translations.carbonReduction[currentLanguage].baseline.header}
              </h2>
              <p>
                {translations.carbonReduction[currentLanguage].baseline.text}
              </p>
              <h2 className="secondary">
                {
                  translations.carbonReduction[currentLanguage].baseline
                    .subHeader
                }
              </h2>
              <p>
                {translations.carbonReduction[currentLanguage].baseline.subText}
              </p>
              <Table
                data={
                  translations.carbonReduction[currentLanguage]
                    .baselineTableData
                }
              />
              <h2
                className="secondary"
                ref={(el) => (sectionRefs.current[3] = el)}
                id="section3"
              >
                Current Emisions Reporting
              </h2>
              <h2 className="secondary">2023</h2>
              <Table
                data={
                  translations.carbonReduction[currentLanguage]
                    .emissionsTableData
                }
              />
              <h2
                className="secondary"
                ref={(el) => (sectionRefs.current[4] = el)}
                id="section4"
              >
                {translations.carbonReduction[currentLanguage].methods.header}
              </h2>
              {translations.carbonReduction[currentLanguage].methods.text.map(
                (text, index) => (
                  <p key={index}>{text}</p>
                )
              )}
              <ul className="resources-list">
                {translations.carbonReduction[currentLanguage].methods.list.map(
                  (list, index) => (
                    <li key={index}>{list}</li>
                  )
                )}
              </ul>
              {translations.carbonReduction[
                currentLanguage
              ].methods.followingText.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
              <h2
                className="secondary"
                ref={(el) => (sectionRefs.current[5] = el)}
                id="section5"
              >
                {
                  translations.carbonReduction[currentLanguage]
                    .reductionProjects.header
                }
              </h2>
              <p>
                {
                  translations.carbonReduction[currentLanguage]
                    .reductionProjects.text
                }
              </p>
              {translations.carbonReduction[
                currentLanguage
              ].reductionProjects.subheadings.map((heading, index) => (
                <div className="resources-text-wrapper" key={index}>
                  <h3 className="secondary">{heading}</h3>
                  <p>
                    {
                      translations.carbonReduction[currentLanguage]
                        .reductionProjects.subText[index]
                    }
                  </p>
                </div>
              ))}
              <h3 className="secondary">
                {translations.carbonReduction[currentLanguage].training.header}
              </h3>
              {translations.carbonReduction[currentLanguage].training.text.map(
                (text, index) => (
                  <p key={index}>{text}</p>
                )
              )}
              <h3 className="secondary">
                {translations.carbonReduction[currentLanguage].office.header}
              </h3>
              {translations.carbonReduction[currentLanguage].office.text.map(
                (text, index) => (
                  <p key={index}>{text}</p>
                )
              )}
              <h2 className="secondary">
                {translations.carbonReduction[currentLanguage].signOff.header}
              </h2>
              {translations.carbonReduction[currentLanguage].signOff.text.map(
                (text, index) => (
                  <p key={index}>{text}</p>
                )
              )}
              {/* <img src={Signiture} />
              <h2 className="secondary">Naomi Porter</h2>
              <p className="secondary">
                {translations.carbonReduction[currentLanguage].signiture.position}
              </p>
              <p className="privacy-policy-subheader">
                {translations.carbonReduction[currentLanguage].signiture.date}
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default CarbonReduction;
