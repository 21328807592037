export const careersTranslations = {
  en: {
    title: "Always looking for new talent",
    why: [
      {
        header: "Why Join?",
        text: [
          "We are a small dynamic and friendly business investing a lot in research & development. We are passionate about producing innovative solutions for policing, which is a tremendous subject.",
          "Our colleagues enjoy the freedom to spend time investigating new technologies (some call it playing with gadgets or new tech); and they are all encouraged to express themselves openly, which in a large organisation would be difficult.",
          "As part of your work with us, you will experience various platforms (cloud, mobile, embedded), developing in a variety of languages such as C, C++, Objective-C, C#, Java, and HTML5 as well as more exotic languages. The point is that it will never be boring.",
          "At Innaxys, we like our developers to work in close collaboration with clients, presenting and discussing their own work with police officers and users without the need for the ‘middle man with a tie’.",
          "What you will get working with us is an exciting yet challenging job but certainly not a 9 to 5 one; you will be an integral part of a supportive, engaging and inclusive team.",
        ],
      },
    ],
    about: [
      {
        header: "About you",
        text: [
          "We are a computer science company and we are looking for people with a good scientific background i.e. mathematics in particular, who can apply their talents in a practical way to develop solutions that will make the difference.",
          "Whether you are a shy genius or communicative geek, we want to hear from you. You must be passionate about what you do and not hesitate to stand by your work and your ideas. Be assured that you will have the interest of everyone here; not least our Chief Executive who started his career as an engineer and a software developer, and even now continues to get involved in this side of the business. , we still correct his bugs today and he is always keen to discuss techniques and new ideas with the team at any and every opportunity.",
        ],
      },
    ],
    recruitment: [
      {
        header: "Recruitment process",
        text: [
          "Firstly, don’t expect any psychological tests or personality assessment because that’s not the way we do things. Here we like originality and we prefer to ‘discover’ you during the process.",
          "We will start with a classic but relaxed face-to-face interview, after which we may ask you to develop a small project based on requirements similar to those we are often set by our clients themselves. You will take this project home and have enough time to develop it. This is not a race so take your time; what we are interested in is the quality of your work, the attention to detail and how you are able to present and defend it. You will even be able to come to see us, share a coffee and ask questions…",
          "Eventually you will present your project and we will discuss it together.",
        ],
      },
    ],
  },
  fr: {
    title: "Toujours à la recherche de nouveaux talents",
    why: [
      {
        header: "Pourquoi adhérer ??",
        text: [
          "Nous sommes une petite entreprise dynamique et conviviale qui investit beaucoup dans la recherche et le développement. Nous sommes passionnés par la production de solutions innovantes pour la police, qui est un sujet extraordinaire.",
          "Nos collègues jouissent de la liberté de passer du temps à étudier les nouvelles technologies (certains appellent cela jouer avec des gadgets ou des nouvelles technologies) ; et ils sont tous encouragés à s'exprimer ouvertement, ce qui serait difficile dans une grande organisation.",
          "Dans le cadre de votre travail avec nous, vous ferez l'expérience de diverses plateformes (cloud, mobile, embarqué), en développant dans une variété de langages tels que C, C++, Objective-C, C#, Java, et HTML5 ainsi que des langages plus exotiques. L'essentiel, c'est qu'on ne s'ennuie jamais.",
          "Chez Innaxys, nous aimons que nos développeurs travaillent en étroite collaboration avec les clients, qu'ils présentent et discutent de leur propre travail avec les officiers de police et les utilisateurs, sans qu'il soit nécessaire de faire appel à un 'intermédiaire avec une cravate'.",
          "Ce que vous obtiendrez en travaillant avec nous, c'est un travail passionnant et stimulant, mais certainement pas un travail de 9 à 5 ; vous ferez partie intégrante d'une équipe qui vous soutiendra, qui vous engagera et qui sera ouverte à tous.",
        ],
      },
    ],
    about: [
      {
        header: "À propos de vous",
        text: [
          "Nous sommes une entreprise d'informatique et nous recherchons des personnes ayant une bonne formation scientifique, notamment en mathématiques, capables d'appliquer leurs talents de manière pratique pour développer des solutions qui feront la différence",
          "Que vous soyez un génie timide ou un geek communicatif, nous voulons vous entendre. Vous devez être passionné par ce que vous faites et ne pas hésiter à défendre votre travail et vos idées. Soyez assuré(e) que vous aurez l'intérêt de tout le monde ici, notamment de notre directeur général qui a commencé sa carrière en tant qu'ingénieur et développeur de logiciels, et qui continue encore aujourd'hui à s'impliquer dans cet aspect de l'entreprise. En tant que directeur général, nous corrigeons encore ses bogues aujourd'hui et il est toujours prêt à discuter des techniques et des nouvelles idées avec l'équipe à chaque occasion",
        ],
      },
    ],
    recruitment: [
      {
        header: "Processus de recrutement",
        text: [
          "Tout d'abord, ne vous attendez pas à des tests psychologiques ou à une évaluation de la personnalité, car ce n'est pas notre façon de faire. Ici, nous aimons l'originalité et nous préférons vous 'découvrir' au cours du processus",
          "Nous commencerons par un entretien classique mais détendu, à l'issue duquel nous vous demanderons peut-être de développer un petit projet basé sur des exigences similaires à celles que nous imposent souvent nos clients eux-mêmes. Vous emporterez ce projet chez vous et disposerez de suffisamment de temps pour le développer. Ce n'est pas une course, alors prenez votre temps ; ce qui nous intéresse, c'est la qualité de votre travail, l'attention que vous portez aux détails et la manière dont vous êtes capable de le présenter et de le défendre. Vous pourrez même venir nous voir, partager un café et poser des questions...",
          "Vous finirez par présenter votre projet et nous en discuterons ensemble",
        ],
      },
    ],
  },
};
