import React from "react";
import Nav from "../components/Nav";
import Officer from "../assets/heros/TRAM 1.svg";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import { useLanguage } from "../config/LanguageContext";
import TramFeatures from "../components/tramFeatures";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../components/headerSections";

function Tram() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  return (
    <>
      <Nav />
      <HeaderSection
        header="TRAM - Training Records & Asset Management Solution"
        buttonText="Request a demo"
        image={Officer}
        onClick={handleBookDemoClick}
      />
      <span className="wraps">
        <div className="right-to-left-triangle-white"></div>
      </span>
      <div className="dytask-content-container">
        <div className="dytask-content-wrapper">
          <h2 className="secondary">
            {translations.tram[currentLanguage].tramContent.header}
          </h2>
          <p>
            Our Training Records & Assets Management (TRAM) Solution is a
            complete cloud-based, mobile-enabled solution to manage the
            administration of the firearms function of security organisations
            (police, border security, private security organisations, etc.). The
            Solution is expandable to include other types of training and asset
            management, such as for fire and rescue services, higher education
            institutes, and any organisation with a requirement to track the
            lifecycle including servicing of high value equipment as well as
            related user competencies and training.
            {/* {translations.tram[currentLanguage].tramContent.textHead} */}
          </p>
        </div>
      </div>
      <div className="left-to-right-triangle-products"></div>
      <div className="dytask-features-container">
        <TramFeatures />
      </div>
      <div className="upside-down-left-to-right-triangle"></div>
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Tram;
