export const cookiesTranslations = {
  en: {
    header: "Cookie Policy",
    contents: {
      header: "Contents",
      listItems: [
        "What are cookies?",
        "How Innaxys uses cookies",
        "Third-party cookies",
        "What are your choices regarding cookies",
        "How to control cookies",
      ],
    },
    intro: [
      "Innaxys Ltd (“us”, “we”, or “our”) uses cookies on innaxys.com (the “Service”). By using the Service, you consent to the use of cookies.",
      "Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.",
    ],
    contexts: [
      {
        header: "How Innaxys uses cookies?",
        text: [
          "When you use and access the Service, we may place a number of cookies files in your web browser.",
          "We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences.",
          "We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:",
        ],
        textList: [
          "Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.",
          "Non-essential cookies: We use third party cookies to count the number of visitors to our website.",
        ],
      },
      {
        header: "Third-party cookies",
        text: "In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.",
      },
      {
        header: "What are your choices regarding cookies",
        text: [
          "If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.",
          "Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.",
        ],
      },
      {
        header: "How to control cookies",
        text: "You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.",
      },
    ],
  },
  fr: {
    header: "Cookie Policy",
    contents: {
      header: "Contents",
      listItems: [
        "What are cookies?",
        "How Innaxys uses cookies",
        "Third-party cookies",
        "What are your choices regarding cookies",
        "How to control cookies",
      ],
    },
    intro: [
      "Innaxys Ltd (“us”, “we”, or “our”) uses cookies on innaxys.com (the “Service”). By using the Service, you consent to the use of cookies.",
      "Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.",
    ],
    contexts: [
      {
        header: "How Innaxys uses cookies?",
        text: [
          "When you use and access the Service, we may place a number of cookies files in your web browser.",
          "We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences.",
          "We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:",
        ],
        textList: [
          "Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.",
          "Non-essential cookies: We use third party cookies to count the number of visitors to our website.",
        ],
      },
      {
        header: "Third-party cookies",
        text: "In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.",
      },
      {
        header: "What are your choices regarding cookies",
        text: [
          "If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.",
          "Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.",
        ],
      },
      {
        header: "How to control cookies",
        text: "You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.",
      },
    ],
  },
};
