import React from "react";
import { useLanguage, useNavigation } from "../config/LanguageContext";
import { useNavigate } from "react-router-dom";

function IndustryDiscover() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  return (
    <>
      <div className="left-to-right-triangle-green"></div>
      <div className="discover-container">
        <h2>
        How can Innaxys help your organisation?
          {/* {translations.discover[currentLanguage].header} */}
          </h2>
        <p className="secondary">
        Our sales team can walk you through anything you need to know
          {/* {translations.discover[currentLanguage].subheading} */}
          </p>
        <button className="cta-button-secondary-large" onClick={handleBookDemoClick}>
          Contact us
          {/* {translations.discover[currentLanguage].ctaButton} */}
        </button>
      </div>
      <div className="upside-down-left-to-right-triangle-green"></div>
    </>
  );
}

export default IndustryDiscover;
