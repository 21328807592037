import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

function Company() {
  return (
    <>
      <Nav />
      Company
      <Footer />
    </>
  )
}

export default Company