export const industryTranslations = {
  en: {
    header: "Innaxys for Industry",
    paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non. Pellentesque nec malesuada dui. Ut dapibus lorem tincidunt erat luctus lobortis. Aenean nec ullamcorper tortor. Sed suscipit felis vitae dui lacinia porttitor.",
    ctaButton: "Book a demo",
  },
  fr: {
    header: "Innaxys pour l'industrie",
    paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu bibendum velit. Aenean rhoncus eleifend enim, id luctus massa lacinia non. Pellentesque nec malesuada dui. Ut dapibus lorem tincidunt erat luctus lobortis. Aenean nec ullamcorper tortor. Sed suscipit felis vitae dui lacinia porttitor.",
    ctaButton: "Réserver une démo",
  }
}